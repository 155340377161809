import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ApicallService } from 'src/app/service/apicall.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-monster-us',
  templateUrl: './monster-us.component.html',
  styleUrls: ['./monster-us.component.css']
})
export class MonsterUsComponent implements OnInit {
  @ViewChild('pdfTable')
  pdfTable!: ElementRef;
  urlid:any;
  // checklapi1:any="";
  // isShow1:boolean=false;
  // isShow2:boolean=true;
  // monsterPage:boolean=false;
  // errorPage:boolean=true;
  data: any;
  response: any;
  ctc: any=[];
  disSalary: any;
  willRelocate: any;
  details: any=[];
  updated: any;
  experience: any=[];
  travel: any;
  highDeg: any;
  recentEmp: any;
  canName: any;
  resumeViewData: any;
  htmlCv: any;
  rLink: any;
  base64code:any
  // showDownload1:boolean=true;
  // showDownload2:boolean=true;
  resumeName: any;
  resumExt: any=[];
  resExt: any="";
  resume: any;
  fstName: any;
  fstName2: any;
  Linkedinurl: any=[];
  twitt: any=[];
  face: any=[];
  LinkedinLink: any;
  facebook: any;
  twitter: any;
  skillsummary: any;
  skillSummaryDiv:boolean=false;
  skillsum: any;
  federated='monsterus';
  navigationId:any
  edit: any;
  constructor(
    private apicallService:ApicallService,
    private domSanitizer: DomSanitizer,
    private titleService:Title,
    private toastr: ToastrService,
    private router: Router,
    private http:HttpClient, 
    private _route: ActivatedRoute,
    private spinner: NgxSpinnerService
    ) { }

    monsterUsJson(){
      return new Promise((resolve) => {
      this.apicallService.getmonsterUsJson(this.urlid).then((data: any) => {
        if (data.status===0 || data.status===403 || data.name==="HttpErrorResponse") {
          localStorage.setItem('federated',this.federated);
          this.router.navigate([`./${this.urlid}`]);
        } else {
          if(data !=null || data !=undefined){
            const email=data.data.email
            if(email !=undefined){
              this.navigationId=btoa(email)
              this.edit="true"
              }
              else{
                this.edit="false"
              }
            resolve(data?.data)
          }else{
            localStorage.setItem('federated',this.federated);
            this.router.navigate([`./${this.urlid}`]);
          }  
        }
      })
    }); 
    }
    resumeJsonData(){
      // this.resumeView=this.apicallService.getResumeData(this.urlid);
      this.apicallService.getMonsterResume(this.urlid).then((data:any) =>{
      if (data.status !=0 || data.status !=403 || data.name !="HttpErrorResponse") {
        this.resumeViewData=data?.data?.originalResumehtml;
        if(this.resumeViewData !=null || this.resumeViewData !=undefined){
          this.htmlCv=this.domSanitizer.bypassSecurityTrustHtml(this.resumeViewData);
          // console.log("RESUME",this.resumeViewData);
        }
        if(this.htmlCv ===undefined ){
          var click=document.getElementById('clickme');
          click?.click()
      }
      }else{
        data=null;
      }
      })
    }
    // apiMonsterUsDatas(){
    //   return new Promise((resolve, reject) => {
    //   this.apicallService.getMonsterUsApi(this.urlid).then((data: any) => {  
    //     if(data.status ==='succeed') {
    //     if(data?.data?.response[0] !=null || data?.data?.response[0] !=undefined){
    //       resolve(data?.data?.response[0])
    //     }else{
    //       this.router.navigate([`./${this.urlid}`]);
    //     }
    //     }
    //     else{
    //         this.router.navigate([`./${this.urlid}`]);
    //     }
    //   })
    // })
    // }

  ngOnInit(): void {
    this._route.paramMap.subscribe(params => {
      this.urlid = params.get('urlid');
      // console.log(this.urlid );
      });
    this.getDatas();
  }
  async getDatas(){
      this.data = await this.monsterUsJson();
      // this.showDownload1=false;
      this.skillSummaryDiv=true;
      this.resumeJsonData();
   
    this.response=this.data;
    if(this.response===null || this.response===undefined){
      this.toastr.warning('No Data Found !');
      localStorage.setItem('federated',this.federated);
    }
    this.canName=this.response?.name;
   if(this.response !=null || this.response !=undefined){
    this.titleService.setTitle(this.canName);
   }
  
   if(this.response?.name===null || this.response?.name===undefined){
    this.titleService.setTitle("Profile");
   }
    if(this.response?.information){
      this.ctc=this.response?.information;
    this.ctc.forEach((element:any) => {
      if(element?.name.includes("Desired Salary")===true){
        this.disSalary=element?.value
        // console.log("CTC",this.disSalary)
      } 
      if(element?.name.includes("Relocate")===true){
        this.willRelocate=element?.value
        // console.log("CTC",this.disSalary)
      } 
      if(element?.name.includes("Travel")===true){
        this.travel=element?.value
        // console.log("CTC",this.disSalary)
      } 
    });
    }
    if(this.response?.details){
      this.details=this.response?.details;
    this.details.forEach((element:any) => {
      if(element?.name.includes("Updated")===true){
        this.updated=element?.value
      }  
    });
    }
   if(this.response?.experiences){
    var exp=this.response?.experiences;
    exp.forEach((element:any) => {
      var eex=element?.summary;
      this.experience.push(element)
    });
    // console.log("EXP",this.experience)
  }
  if(this.response?.additionalInformation){
    var add=this.response?.additionalInformation;
    add.forEach((element:any) => {
      if(element?.name.includes("Highest Education")===true){
        this.highDeg=element?.value;
        // console.log("CTC",this.disSalary)
      }
      if(element?.name.includes("Most Recent Employer")===true){
        this.recentEmp=element?.value;
        // console.log("CTC",this.disSalary)
      } 
    });
  }
  // ------------------------Social-Link-----------------------------------
if(this.response?.links !=null || this.response?.links !=undefined){
  if(this.canName !=null || this.canName !=undefined){
   var fname=this.canName.split(" ");
   var seachName=fname[0];
   var seachName2=fname[1];
   this.fstName=seachName.toLowerCase(seachName)
   this.fstName2=seachName2.toLowerCase(seachName2)
  }
   this.response?.links.forEach((link:any)=>{
       if(link.includes("linkedin.com")===true){
         var linkin=link;
         if(linkin.includes(this.fstName)===true || linkin.includes(this.fstName2)===true){
           this.Linkedinurl.push(link)
           }
         }
         if(link.includes("twitter.com")===true){
         this.twitt.push(link)
         }
         if(link.includes("facebook.com")===true){
           this.face.push(link)
         }
       })
        this.LinkedinLink=this.Linkedinurl[0];
        this.twitter=this.twitt[0];
        this.facebook=this.face[0];
      //  console.log('LINK1',this.facebook);
     }
       // console.log('LINK',this.link);
 // -----------------------End-Social-Link--------------

 if(this.response?.skillsummary !=undefined || this.response?.skillsummary !=null){
  this.skillsum=this.response?.skillsummary;
 this.skillsummary=this.skillsum.split(",");
      //  console.log('LINK',this.skillsummary);
 }

}
  redirectToInSearch(){
    this.rLink=`${environment.iSearch}/#/InternalSearch/candidateProfileView/${this.navigationId}?data=primary`;
  }

  // getPdf() {
  //    const div:any = document.getElementById('convert');
  //    const options = {
  //     background: 'white',
  //     scale: 3 
  //     };
  //     html2canvas(div, options).then((canvas) => {
  //     var img = canvas.toDataURL("image/PNG");
  //     var doc = new jsPDF('l', 'mm', 'a4', );
  //      const bufferX = 5;    
  //      const bufferY = 5;   
  //      const imgProps = (<any>doc).getImageProperties(img);   
  //      const pdfWidth = doc.internal.pageSize.getWidth() - 2 * bufferX;
  //      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;  
  //      doc.addImage(img, 'PNG', bufferX, bufferY, pdfWidth, pdfHeight, undefined, 'FAST');  
  //      return doc;   
  //     }).then((doc) => {   
  //      doc.save('Resume.pdf');    
  //     }); 
  //    }

  public getPdf(): void {
    this.spinner.show();
      const DATA = this.pdfTable.nativeElement;  
      let options: any = {   
       orientation: 'p',    
       unit: 'px',   
       format: 'a1',   
      };    
      const doc: jsPDF = new jsPDF(options);   
      doc.html(DATA, {    
       callback: (doc) => {  
        this.spinner.hide();  
        doc.save(`${this.response.name}.pdf`);   
       }
    
      });
     }

  // download(htmlCv:any){
  //   let printContents = htmlCv?.changingThisBreaksApplicationSecurity;
  //   let originalContents = document.body.innerHTML;
  //   document.body.innerHTML = printContents; 
  //   window.print();
  //   document.body.innerHTML = originalContents;
  //   window.location.reload();
  // }

  
}
