import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl, Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ApicallService } from 'src/app/service/apicall.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-dice',
  templateUrl: './dice.component.html',
  styleUrls: ['./dice.component.css']
})
export class DiceComponent implements OnInit {
  urlid: any;
  checklapi1:string="";
  data: any;
  // dicePage:boolean=false;
  // errorPage:boolean=true;
  response:any=[];
  diceUi:boolean=false;
  pageNotFound:boolean=true;
  t:any;
  // isShow1:boolean=false;
  // isShow2:boolean=true;
  hidelive:boolean=false;
  // hidelocal:boolean=true;
  location: any;
  education: any=[];
  educationsData: any=[];
  phoneNo: any;
  skills: any=[];
  skillsData: any=[];
  experienceData: any=[];
  experience: any=[];
  preferredLocations: any=[];
  isWillingToRelocate: any;
  isTdParty: any;
  skdat: any;
  diceResumeData: any;
  htmlCv:any;
  resumeViewData:any;
  base64code:any
  base64code2:any
  rLink:any;
  canName:any;
  base64: any;
  link: any;
  LinkedinLink: any;
  fstName: any;
  fstName2: any;
  Linkedinurl: any=[];
  twitter: any;
  twitt: any=[];
  face:any=[];
  facebook: any;
  pdfSrc:SafeResourceUrl | undefined;
  resumeName:any;
  resumExt:any=[];  
  resExt: any="";
  resume: any;
  diceemail: any;
  ext: any;
  resumeData: any;
  urlSafe: SafeResourceUrl | undefined;
  federated:string="dice";
  navigationId:any
  edit: any;
  constructor( 
    private apicallService:ApicallService,
    private domSanitizer: DomSanitizer,
    private titleService:Title,
    private http:HttpClient, private _route: ActivatedRoute,
    private toastr: ToastrService, 
    private router: Router
    ) { }
   
  ngOnInit(): void {
    this._route.paramMap.subscribe(params => {
      this.urlid = params.get('urlid');
      });
      this.getDatas();
  }
  diceJson(){
    return new Promise((resolve) => {
    this.apicallService.getDiceJson(this.urlid).then((data: any) => {
      if (data.status===0 || data.status===403 || data.name==="HttpErrorResponse") {
        this.checklapi1="error";
        localStorage.setItem('federated',this.federated);
        this.router.navigate([`./${this.urlid}`]);
      } else {
        if(data !=null || data !=undefined){
          const email=data.candidateEmail
          if(email !=undefined){
            this.navigationId=btoa(email)
            this.edit="true"
            }
            else{
              this.edit="false"
            }
          resolve(data)
        }else{
          localStorage.setItem('federated',this.federated);
          this.router.navigate([`./${this.urlid}`]);
        }
      }
    })
  }); 
  }
  diceResume(){
    this.apicallService.getDiceResume(this.urlid).then((data: any) => {
      this.resumeViewData=data?.resume;
      if(this.resumeViewData !=undefined || this.resumeViewData !=null){
      let blob;
      if(data?.ContentType ==='application/pdf'){
           const byteArray = new Uint8Array( atob(this.resumeViewData).split("").map(char => char.charCodeAt(0)));
          const file = URL.createObjectURL( blob = new Blob([byteArray],  { type: 'application/pdf' }));
          this.resumeData = file;
      }
      else{
           const byteArray = new Uint8Array( atob(this.resumeViewData).split("").map(char => char.charCodeAt(0)));
          // const file = URL.createObjectURL( blob = new Blob([byteArray],  { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'}));
          const file = URL.createObjectURL( blob = new Blob([byteArray],  { type: 'application/msword' }));
          this.pdfSrc =file;
        }
      }
    })
  }
  async getDatas(){
      this.data = await this.diceJson();
      this.diceResume(); 
    this.response=this.data;
    if(this.response===null || this.response===undefined){
      localStorage.setItem('federated',this.federated);
      this.router.navigate([`./${this.urlid}`]);     
    }
    // ------------------------Title-----------------------------------------
    this.canName=this.response?.name;
    if(this.response !=null || this.response !=undefined){
     this.titleService.setTitle(this.canName);
    }
    if(this.response?.name===null || this.response?.name===undefined){
     this.titleService.setTitle("Profile");
    }

    // -----------------------End-Title-----------------------------------------
    
    if(this.response?.locations !=null || this.response?.locations !=undefined){
      if(this.response?.locations[0] !=null || this.response?.locations[0] !=undefined){
        this.location=this.response?.locations[0].text;
      }
    }
    if(this.response?.educations !=undefined || this.response?.educations !=null){
      if(this.response?.educations[0] !=undefined || this.response?.educations[0] !=null ||this.education?.org !=null || this.education?.org !=undefined){
        this.education=this.response?.educations[0];
        this.educationsData=this.education?.degree +" @"+this.education?.org;
      }
    }
    if(this.response?.phoneSources !=null || this.response?.phoneSources !=undefined){
      if(this.response?.phoneSources[0] !=null || this.response?.phoneSources[0] !=undefined){
        this.phoneNo=this.response?.phoneSources[0]?.v;
      }
    }
    if(this.response?.skillScores !=null || this.response?.skillScores !=undefined){   
      this.skills=this.response?.skillScores;
      this.skills.forEach((element:any) => {
        this.skillsData.push(element)
      });
    }
    if(this.response?.emailSources){
      if(this.response?.emailSources[0]){
       this.diceemail=this.response?.emailSources[0]?.v;
      }
     }
   
   if(this.response?.experience){
    this.experienceData=this.response?.experience?.history;   
   }
// -------------------------Preferred Locations---------------------------------------------------------------
    if(this.response?.preferredLocations===null ||this.response?.preferredLocations===undefined){
      this.preferredLocations=["Unspecified"]
    }else{
      var prLocation=this.response?.preferredLocations;
      prLocation.forEach((ele:any)=>{
        this.preferredLocations.push(ele.text);
      })
    }
// ------------------------- End Preferred Locations---------------------------------------------------------------

// ----------------------Resume View----------------------------------------------
if(this.response?.resumeName !=null){
  this.resumeName=this.response?.resumeName;
  var resName=this.resumeName.split(".")
  resName.forEach((data:any)=>{
    this.resumExt.push(data)
  })
  var nemeExtention=this.resumExt?.length-1;
  this.resumeName=resName[nemeExtention];
  if(this.resumeName=="pdf"){
    this.resExt="url";
  }else{
    this.resExt="office";
  }
  this.resume=`${this.response?.resume}#toolbar=0`;
}

// ----------------------End Resume View----------------------------------------------

  //  ---------------------Willing To Relocate---------------------------------
  if(this.response?.isWillingToRelocate !=null || this.response?.isWillingToRelocate !=undefined){
    if(this.response?.isWillingToRelocate===true){
      this.isWillingToRelocate="Yes"
    }else{
      this.isWillingToRelocate="No"
    }
  }
  //  ---------------------End Willing To Relocate---------------------------------
  //  ---------------------Third Party---------------------------------
  if(this.response?.isThirdParty !=null || this.response?.isThirdParty !=undefined){
    if(this.response?.isThirdParty===true){
      this.isTdParty="Yes"
    }else{
      this.isTdParty="No"
    }
  }
  //  ---------------------End Third Party---------------------------------

  
// ------------------------Social-Link-----------------------------------
if(this.response?.linkUrls !=null || this.response?.linkUrls !=undefined){
 if(this.canName !=null || this.canName !=undefined){
  var fname=this.canName.split(" ");
  var seachName=fname[0];
  var seachName2=fname[1];
  this.fstName=seachName.toLowerCase(seachName)
  this.fstName2=seachName2.toLowerCase(seachName2)
 }
  this.response?.linkUrls.forEach((link:any)=>{
      if(link.includes("linkedin.com")===true){
        var linkin=link;
        if(linkin.includes(this.fstName)===true || linkin.includes(this.fstName2)===true){
          this.Linkedinurl.push(link)
          }
        }
        if(link.includes("twitter.com")===true){
        this.twitt.push(link)
        }
        if(link.includes("facebook.com")===true){
          this.face.push(link)
        }
      })
      this.LinkedinLink=this.Linkedinurl[0];
      this.twitter=this.twitt[0];
      this.facebook=this.face[0];
    }
// -----------------------End-Social-Link--------------

  }

// -----------------------Redirect-to-Internal-Search-----------------------------------
  redirectToInSearch(){
    this.rLink=`${environment.iSearch}/#/InternalSearch/candidateProfileView/${this.navigationId}?data=primary`;
  }
// ----------------------End-Redirect-to-Internal-Search-----------------------------------

  scroll(content:any){
    var element = document.getElementById(content);
    element?.scrollIntoView(true);
  }
   // ------------------Download Resume From Dice JSON--------------------------------
   async downloadResume() {
    let blob;
    await this.apicallService.getDiceResume(this.urlid).then(data => {
      if (data.data !== null || data.data !== undefined) {
        this.base64code = data?.resume;
        if(data.ContentType==='application/pdf'){
          this.ext="pdf";
        }
        else{
          this.ext="doc"
        }
        const ext = this.ext;
        if (this.base64code === 'not found.') {
          alert('Resume Not Found!');
        }
        else {
          const byteArray = new Uint8Array(atob(this.base64code).split('').map(char => char.charCodeAt(0)));
            if (ext === 'pdf' || ext === 'doc' ||ext === 'docx') {
            let a = document.createElement("a");
            if(this.canName === undefined || this.canName === null){
              this.canName="profile";
            }
            else if(ext === 'pdf'){
              a.href = URL.createObjectURL( blob = new Blob([byteArray],  { type: 'application/pdf' }));
              a.download = `${this.canName}`
              a.click();
              this.toastr.success('Download Successfully');            
            }
            else if(ext === 'doc'){
              a.href = URL.createObjectURL( blob = new Blob([byteArray], { type: 'application/msword' }));
              a.download = `${this.canName}`
              a.click();
              this.toastr.success('Download Successfully');  
            }
            else if(ext === 'docx'){
              a.href = URL.createObjectURL( blob = new Blob([byteArray], { type: 'application/msword' }));
              a.download = `${this.canName}`
              a.click();
              this.toastr.success('Download Successfully');
            }
          }
        }
      }
      else {
        this.toastr.warning('Resume Not Found!');
      }
    });
  }
  // -----------------End-Download Resume From Dice JSON--------------------------------

  // ------------------Download Resume From Dice API------------------------------------
   async downloadDiceResume() {
    let blob;
    await this.apicallService.getResumeDowload(this.urlid).then(data => {
      if (data?.data?.message !="Invalid Id!") {
        this.base64code2 = data?.data?.response?.resume_data;
        const ext = this.resumeName;
        if (this.base64code2 === 'not found.') {
          alert('Resume Not Found!');
        }
        else {
          const byteArray = new Uint8Array(atob(this.base64code2).split('').map(char => char.charCodeAt(0)));
            if (ext === 'pdf') {
            let a = document.createElement("a");
            if(this.canName === undefined || this.canName === null){
              this.canName="profile"
            }
            else if(ext === 'pdf'){
              a.href = URL.createObjectURL( blob = new Blob([byteArray],  { type: 'application/pdf' }));
              a.download = `${this.canName}`
              a.click();
              this.toastr.success('Download Successfully');            
            }
            else if(ext === 'doc'){
              a.href = URL.createObjectURL( blob = new Blob([byteArray], { type: 'application/msword' }));
              a.download = `${this.canName}`
              a.click();
              this.toastr.success('Download Successfully');  
            }
            else if(ext === 'docx'){
              a.href = URL.createObjectURL( blob = new Blob([byteArray], { type: 'application/msword' }));
              a.download = `${this.canName}`
              a.click();
              this.toastr.success('Download Successfully');
            }
          }
        }
      }
      else {
        this.toastr.warning('Resume Not Found!');
      }
    });
  }

// -----------------End-Download Resume From Dice API--------------------------------
}
