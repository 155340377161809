<div class="container-fluid" [hidden]="diceUi">
    <div class="mb-3 mt-3">
        <div class="card">
            <div class="profile flex-row">
                <div class="left-container">
                    <div class="profile-avatar">
                        <div class="candidate-avatar">
                            <img src="./assets/img/profile.png" alt="" />
                        </div>
                    </div>
                </div>
                <div class="right-container">
                    <div class="flex-row">
                        <div class="details-wrapper">
                            <div class="candidate-headline flex-row flex-aic">
                                <span class="name ellipsis" title="{{response?.name}}">
                                    <span class="hlite-inherit">
                                        <span class="hlite-inherit">
                                            {{response?.name}}
                                        </span>
                                        <span class="hlite-inherit" >
                                            <!-- {{noName|uppercase}} -->
                                        </span>
                                    </span>
                                </span>
                                <button
                                class="review-later-btn rdx-action naukri-btn-tertiary naukri-btn-small"
                                type="button">
                                <div class="naukri-tooltip-wrapper">
                                    <span title="Redirect to Internal Search" (click)="redirectToInSearch()" *ngIf="edit==='true'"><a target="_blank" [href]="rLink"><em class="far fa-edit edit"></em></a></span>
                                    <span title="Dice Live Data"><img class="live-img"
                                            src="./assets/img/live1.jpg" alt=""></span>
                                    <!-- <span [hidden]="isShow2" title="Dice Local Data"><img
                                            class="local-img" src="./assets/img/local.png"
                                            alt=""></span> -->
                                    <!-- <span title="Bookmark">
                                        <em class="fa fa-bookmark save-ico  naukri-icon-bookmark_grey"
                                            aria-hidden="true"></em>
                                        <span class="save-later-btn label"
                                            style="display: none;">Save for later</span>
                                    </span> -->
                                </div>
                            </button>
                            </div>
                            <div class="candidate-overview flex-row flex-aic">
                                <div class="meta-data">
                                    <em title="Total Work Experience"
                                        class="fa fa-calendar ico text-muted"
                                        aria-hidden="true"></em>&nbsp;&nbsp;
                                    <span>{{response?.totalWorkExperience ?? "0y"}}</span>
                                </div>
                                <div class="meta-data" *ngIf="location">
                                    <em title="Current location"
                                        class="fa fa-map-marker-alt text-muted  naukri-icon-place"
                                        aria-hidden="true"></em>&nbsp;&nbsp;
                                    <span title="{{location}}"
                                        class="location">{{location}}</span>
                                </div>
                                <div class="meta-data" *ngIf="isWillingToRelocate">
                                    <em title="Relocation Info"
                                        class="fa fa-map text-muted  naukri-icon-place"
                                        aria-hidden="true"></em>&nbsp;&nbsp;
                                    <span class="location">{{isWillingToRelocate}}</span>
                                </div>
                                <div class="meta-data" *ngIf="response?.workPermitLocations">
                                    <em title="Work Auth"
                                        class="fa fa-globe text-muted  naukri-icon-place"
                                        aria-hidden="true"></em>&nbsp;&nbsp;
                                    <span title="{{response?.workPermitLocations}}"
                                        class="location">{{response?.workPermitLocations}}</span>
                                </div>
                                <div class="meta-data" *ngIf="response?.annualSalary">
                                    <img title="Desired Annual Salary" src="./assets/img/ctc.png" width="16px" alt="">
                                    <span>&nbsp; {{response?.annualSalary?.min}} + {{response?.annualSalary?.currency}}/yr</span>
                                </div>
                                <div class="meta-data" *ngIf="response?.workPermitDocuments">
                                    <img title="Work Permit Documents" src="./assets/img/verified-account.png" width="16px" alt="">
                                    <!-- <em title="Work Permit Documents"
                                        class="fa fa-book text-muted naukri-icon-place"
                                        aria-hidden="true"></em> -->
                                        &nbsp;&nbsp;
                                    <span title="{{response?.workPermitDocuments}}"
                                        class="location">{{response?.workPermitDocuments}}</span>
                                        <span [hidden]="true">{{diceemail}}</span>
                                </div>

                            </div>
                            <div class="previous" *ngIf="response?.currentTitle || response?.latestCompany">
                                <span>
                                    <em title="Previous Job Title and Organization" class="fa fa-briefcase text-muted"></em>&nbsp;&nbsp;<span class="text-muted">Previous:</span>
                                </span>
                                <span class="previousJob">{{response?.currentTitle}} <span class="text-info">@ {{response?.latestCompany}}</span></span>
                            </div>
                            <div class="preferre" *ngIf="response?.preferredJobTitle">
                                <span>
                                    <em title="Preferred Job Title" class="fa fa-briefcase text-muted"></em>&nbsp;&nbsp;<span class="text-muted">Preferred:</span>
                                </span>
                                <span class="preferreValue">{{response?.preferredJobTitle}}</span>
                            </div>
                            <!-- <div *ngIf="this.response?.educations">
                                <span>
                                    <i class="fa fa-graduation-cap text-muted"></i>&nbsp;<span class="text-muted">Highest degree:</span>
                                </span>&nbsp;&nbsp;&nbsp;
                                <span>{{educationsData}}
                                </span>
                            </div> -->
                            <div class="phoneEmail">
                                <span>
                                    <span *ngIf="phoneNo">
                                        <i title="phone" class="fa fa-mobile-alt text-muted" aria-hidden="true"></i>&nbsp;&nbsp;&nbsp;<span class="text-muted">Phone:</span>
                                        <span class="text-primary mrg">{{phoneNo}}</span>
                                    </span>
                                    <span *ngIf="response?.candidateEmail">
                                        <span class="email"><i title="Email" class="fa fa-envelope text-muted" aria-hidden="true"></i>&nbsp;&nbsp;
                                            <span class="text-muted">Email:</span>&nbsp;&nbsp;<span>{{response?.candidateEmail}}</span>
                                        </span>
                                    </span>
                                </span>
                                <div class="social">
                                    <a [href]="LinkedinLink" target="_blank" *ngIf="LinkedinLink"><img title="Follow on LinkedIn" src="./assets/img/linkedin.png" width="19px" alt="LinkedIn"></a>&nbsp;
                                    <a [href]="facebook" target="_blank" *ngIf="facebook"><img title="Follow on Facebook" src="./assets/img/Facebook.png" width="15px" alt="Facebook"></a>&nbsp;
                                    <a [href]="twitter" target="_blank" *ngIf="twitter"><img title="Follow on Twitter" src="./assets/img/twitter.png" width="17px" alt="Twitter"></a>  
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
           
        </div>
    </div>
    <div class="tuple-footer">
        <span class="flex-row flex-aic left-item" title="">
            <!-- <em class="fa fa-eye ico  naukri-icon-remove_red_eye f-14"></em> -->
            <!-- <span>{{response?.numberOfViews}}</span> -->
        </span>
        <div class="tuple-meta fr"><span class="download-status fl">
        <span class="flex-row flex-aic item">Modified on: {{response?.dateUpdated | date}}</span></span>
        <span *ngIf="response?.dateLastActive" class="flex-row flex-aic item">Active: {{response?.dateLastActive| date}}</span></div>
</div>
    <div style="border-top: 1px solid #bec4d2;" class="main-divitem">
        <div class="stiky">
            <ul class="nav nav-tabs nav-justified" role="tablist">
                <li class="nav-item" *ngIf="resumeViewData || resume">
                    <div class="nav-link active" data-bs-toggle="tab" (click)="scroll('content')">Resume</div>
                </li>

                <li class="nav-item" *ngIf="resumeViewData || resume">
                    <div class="nav-link" data-bs-toggle="tab" (click)="scroll('content0')">Skill</div>
                </li>
                <li class="nav-item" *ngIf="!resume && !resumeViewData">
                    <div class="nav-link active" data-bs-toggle="tab" (click)="scroll('content0')">Skill</div>
                </li>

                <li class="nav-item">
                    <div class="nav-link" data-bs-toggle="tab" (click)="scroll('content1')" *ngIf="experienceData || response?.educations"><span *ngIf="experienceData">Work Histry</span> <span *ngIf="response?.educations">& Education</span></div>
                </li>

                <li class="nav-item" *ngIf="response?.bio">
                 <div class="nav-link" data-bs-toggle="tab" (click)="scroll('content2')">Summary</div>
                </li>

                <li class="nav-item" *ngIf="response?.linkUrls">
                    <div class="nav-link" data-bs-toggle="tab" (click)="scroll('content3')">Social</div>
                </li>
                
                <li class="nav-item">
                    <div class="nav-link" data-bs-toggle="tab" (click)="scroll('content4')">Work Preferences</div>
                </li>
                
            </ul>
        </div>
        <div id="content" class="mt-4 card"  *ngIf="resumeViewData || resume || resumeData" >
            <div class="skillDiv2">Resume
                 <a [hidden]="hidelive" (click)="downloadResume()">&nbsp;&nbsp;&nbsp;<em class="fas fa-download download text-primary"></em></a>
                 <!-- <a [hidden]="hidelocal" (click)="downloadDiceResume()">&nbsp;&nbsp;&nbsp;<em class="fas fa-download download text-primary"></em></a> -->
                </div>
            <!-- <pdf-viewer *ngIf="resumeViewData" [src]="pdfSrc" 
            style="display: block; height: 400px; width: 100%;"
            ></pdf-viewer> -->
            <ngx-doc-viewer *ngIf="resumeData"
                [url]="resumeData"
                viewer="url"
                style="width:100%;height:100vh;">
            </ngx-doc-viewer>
            <!-- <iframe src="{{pdfSrc}}" height="700px" frameborder="0"></iframe> -->
            <!-- <ngx-doc-viewer *ngIf="pdfSrc"
                url="{{pdfSrc}}"
                viewer="office"
                style="width:100%;height:100vh;">
            </ngx-doc-viewer> -->

            <div *ngIf="pdfSrc" class="resAvail" [hidden]="hidelive">
                <span style="font-weight: bold; color: lightslategray;">Resume available!!</span>&nbsp; <a class="downloadBtn text-primary" (click)="downloadResume()"><img src="./assets/img/click.png" width="100px" alt=""></a>
            </div>
            
            <div *ngIf="response?.resume">
                <ngx-doc-viewer
                    [url]="resume"
                    [viewer]="resExt"
                    style="width:100%;height:100vh;">
                </ngx-doc-viewer>
                <!-- <ngx-doc-viewer 
                    url="{{urlSafe}}"
                    viewer="url"
                    style="width:100%;height:100vh;">
            </ngx-doc-viewer> -->
            </div>
        </div>

        <div id="content0" class="card mt-4">
            <div class="skillDiv">Skills</div>
            <ul id="horizontal-list" [hidden]="hidelive">
                <li *ngFor="let skData of skillsData">
                    <div class="sksk">
                        <div class="sk"><span title="{{skData?.skill}} ">{{skData?.skill}}</span></div>
                    </div>
                    <div class="yr">
                        <span class="yrs" *ngIf="skData?.yearsOfExperience"> {{skData?.yearsOfExperience}}yrs </span>&nbsp;&nbsp;
                        <span *ngIf="skData?.yearLastUsed !='-1'">{{skData?.yearLastUsed}}</span>
                    </div>
                </li>
            </ul>
            <!-- <div class="table table_data" [hidden]="hidelocal">

                <ul id="horizontal-listhData">
                    <li *ngFor="let skData of skillsData">{{skData.skill}}</li>
                </ul>

            </div> -->
        </div>

        <div id="content1" class="mt-4 card" *ngIf="experienceData || response?.educations">
            <div class="skillDiv">Work History</div>
            <ul id="horizontal-list2">
                <li *ngFor="let experience of experienceData">
                    <div class="edu-head">
                        <div class="edu-icon flex-row flex-aic flex-jcc">
                            <em class="fa fa-briefcase" aria-hidden="true"></em>
                        </div>
                        <div class="edu-label">
                            <div class="desig"><span >{{experience?.company}} <span class="text-muted" *ngIf="experience?.title">- at </span> {{experience?.title}}</span> </div>
                            <div class="dates"><span class="institue"><span class="hlite-inherit">
                                <span class="hlite-inherit"></span>{{experience?.periodStart?.generated}} <span *ngIf="experience?.periodStart?.generated">- {{experience?.periodEnd?.generated ??'No end date'}}</span></span></span>
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
            <div *ngIf="response?.educations">
                <div class="skillDiv">Education</div>
                <ul id="horizontal-list2">
                    <li *ngFor="let edu of response?.educations">
                        <div class="edu-head">
                            <div class="edu-icon flex-row flex-aic flex-jcc"><em
                                    class="fa fa-graduation-cap ico ico-info educ-logo  naukri-icon-school-1"></em>
                            </div>
                            <div class="edu-label">
                                <div class="desig"><span >{{edu?.degree}}</span> </div>
                                <div class="dates"><span class="institue"><span class="hlite-inherit">
                                    <span class="hlite-inherit"></span><span>{{edu?.org}}</span></span></span>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>

        <div id="content2" class="mt-4 card" *ngIf="response?.bio">
            <div class="skillDiv">Summary</div>
            <div class="summary">
                {{response?.bio}}
            </div>
        </div>
   
        <div id="content3" class="mt-4 card" *ngIf="response?.linkUrls">
            <div class="skillDiv2">Social</div>
            <div class="skillDiv text-info">Other Related Links:</div>
            <ul>
                <li *ngFor="let link of response?.linkUrls"><a target="_blank" [href]="link">{{link}}</a></li>
            </ul>
        </div>

        <div id="content4" class="mt-4 card">
            <div class="skillDiv">Work Preferences</div>
            <div class="work-details">
                <div class="detail" *ngIf="this.response?.isWillingToRelocate">
                    <div class="work-title">Relocate:</div>
                    <div class="content">{{isWillingToRelocate}}</div>
                </div>
                <div class="detail">
                    <div class="work-title">Preferred Location:</div>
                    <div class="content"><ul><li *ngFor="let pre of preferredLocations">{{pre}}</li></ul></div>
                </div>
                <div class="detail">
                    <div class="work-title">Travel Preference:</div>
                    <div class="content">Prefers No Travel</div>
                </div>
                <div class="detail" *ngIf="response?.workPermitLocations">
                    <div class="work-title">Work Authorization:</div>
                    <div class="content">{{response?.workPermitLocations}}</div>
                </div>
                <div class="detail" *ngIf="response?.hourlyRate?.min">
                    <div class="work-title">Desired Hourly Rate:</div>
                    <div class="content">{{response?.hourlyRate?.min}}+ ({{response?.hourlyRate?.currency}})</div>
                </div>
                <div class="detail" *ngIf="response?.annualSalary?.min">
                    <div class="work-title">Desired Salary:</div>
                    <div class="content">{{response?.annualSalary?.min}} + {{response?.annualSalary?.currency}}/yr</div>
                </div>
                <div class="detail">
                    <div class="work-title">Security Clearance:</div>
                    <div class="content">Unspecified</div>
                </div>
                <div class="detail" *ngIf="this.response?.isThirdParty">
                    <div class="work-title">Third Party:</div>
                    <div class="content">{{isTdParty}}</div>
                </div>
                <div class="detail" *ngIf="response?.employmentTypes">
                    <div class="work-title">Employment Type:</div>
                    <div class="content"><ul><li *ngFor="let pre of response?.employmentTypes">{{pre}}</li></ul></div>
                </div>
            </div>
        </div>
        

    </div>
</div>
<!-- <div [hidden]="errorPage">
    <app-error-page></app-error-page>
</div> -->
<ng-http-loader></ng-http-loader>