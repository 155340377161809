<div class="container-fluid">
    <div class="candidate-headline flex-row flex-aic">
        <button
            class="review-later-btn rdx-action naukri-btn-tertiary naukri-btn-small"
            type="button">
            <div class="naukri-tooltip-wrapper">
                <span title="Edit" (click)="redirectToInSearch()"><a [href]="rLink" target="_blank"><em class="far fa-edit edit"></em></a></span>  
                <span title="Indeed Live Data"><img class="live-img"
                    src="./assets/img/live1.jpg" alt=""></span>
               
            </div>
        </button>
    </div>
    <div>
        <iframe [srcdoc]="html" title="Indeed" height="700px" width="100%"  style="zoom: 1.5;" frameborder="0"></iframe>
    </div>
</div>