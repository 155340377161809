<div class="container-fluid">
    <div class="mb-3 mt-3">
        <div class="card">
            <div class="profile flex-row">
                <div class="left-container">
                    <div class="profile-avatar">
                        <div class="candidate-avatar">
                            <img src="./assets/img/profile.png" alt="" />
                        </div>
                    </div>
                </div>
                <div class="right-container">
                    <div class="flex-row">
                        <div class="details-wrapper">
                            <div class="candidate-headline flex-row flex-aic">
                                <span class="name ellipsis" title=" {{((response?.firstName)?(response?.firstName):'')+' '+((response?.middleName)?(response?.middleName):'')+' '+((response?.lastName)?(response?.lastName):'')}}">
                                    <span class="hlite-inherit">
                                        <span class="hlite-inherit" *ngIf="((response?.firstName)?(response?.firstName):'')+' '+((response?.middleName)?(response?.middleName):'')+' '+((response?.lastName)?(response?.lastName):'')">
                                            {{((response?.firstName)?(response?.firstName):'')+' '+((response?.middleName)?(response?.middleName):'')+' '+((response?.lastName)?(response?.lastName):'')}}<span *ngIf="response?.pronouns">{{(response?.pronouns)}}</span>
                                        </span>
                                        <span class="hlite-inherit" *ngIf="!(((response?.firstName)?(response?.firstName):'')+' '+((response?.middleName)?(response?.middleName):'')+' '+((response?.lastName)?(response?.lastName):''))">
                                            {{response?.email|uppercase}}
                                        </span>
                                    </span>
                                </span>
                                <!-- <button
                                class="review-later-btn rdx-action naukri-btn-tertiary naukri-btn-small"
                                type="button">
                                <div class="naukri-tooltip-wrapper">
                                    <span title="Redirect to Internal Search" (click)="redirectToInSearch()"><a target="_blank" [href]="rLink"><em class="far fa-edit edit"></em></a></span>
                                    <span title=" Linkedin Live Data"><img class="live-img"
                                            src="./assets/img/live1.jpg" alt=""></span>
                                    
                                </div>
                            </button> -->
                            </div>
                            <div class="jobtitlediv" *ngIf="response?.headline">
                                {{response?.headline}}
                            </div>
                            <div class="candidate-overview flex-row flex-aic">
                                <div class="meta-data">
                                    <em title="Total Work Experience"
                                        class="fa fa-calendar ico text-muted"
                                        aria-hidden="true"></em>&nbsp;&nbsp;
                                    <span>{{length? length +' ' + 'year': 0+' '+'year'}}</span>
                                </div>
                                <div class="meta-data" *ngIf="response?.location">
                                    <em title="Current location"
                                        class="fa fa-map-marker-alt text-muted  naukri-icon-place"
                                        aria-hidden="true"></em>&nbsp;&nbsp;
                                    <span title="location"
                                        class="location">{{response?.location?.displayName+','+response?.location?.countryCode}}</span>
                                </div>
                                <div class="meta-data" *ngIf="willRelocate">
                                    <em title="Relocation Info"
                                        class="fa fa-map text-muted  naukri-icon-place"
                                        aria-hidden="true"></em>&nbsp;&nbsp;
                                    <span class="location">{{willRelocate}}</span>
                                </div>
                                <div class="meta-data" *ngIf="response?.location?.countryCode">
                                    <em title="Authorized Country"
                                        class="fa fa-globe text-muted  naukri-icon-place"
                                        aria-hidden="true"></em>&nbsp;&nbsp;
                                    <span title="response?.workPermitLocations"
                                        class="location">{{response?.location?.countryCode}}</span>
                                </div>
                                <div class="meta-data" *ngIf="disSalary">
                                    <img title="Desired Annual Salary" src="./assets/img/ctc.png" width="16px" alt="">
                                    <span>&nbsp;{{disSalary}}</span>
                                </div>

                            </div>
                            
                            <div class="phoneEmail">
                                <!-- <span> -->
                                    <!-- <span *ngIf="response?.phone">
                                        <i title="phone" class="fa fa-mobile-alt text-muted" aria-hidden="true"></i>&nbsp;&nbsp;&nbsp;<span class="text-muted">Phone:</span>
                                        <span class="text-primary mrg">{{response?.phone}}</span>
                                    </span> -->
                                    <span *ngIf="response?.summary">
                                        <span class="email">
                                            <!-- <i title="Email" class="fa fa-envelope text-muted" aria-hidden="true"></i>&nbsp;&nbsp; -->
                                            <span class="text-muted1">Summary:</span>&nbsp;&nbsp;<span style=" display: inline-block;
                                            width: 66%;
                                            white-space: nowrap;
                                            overflow: hidden !important;
                                            text-overflow: ellipsis;"  title="{{response?.summary}}">{{response?.summary}}</span>
                                        </span>
                                    </span>
                                <!-- </span> -->
                                <div class="social">
                                    <a *ngIf="LinkedinLink" [href]="LinkedinLink" target="_blank" ><img title="Follow on LinkedIn" src="./assets/img/linkedin.png" width="19px" alt="LinkedIn"></a>&nbsp;
                                    <a *ngIf="facebook" [href]="facebook" target="_blank" ><img title="Follow on Facebook" src="./assets/img/Facebook.png" width="15px" alt="Facebook"></a>&nbsp;
                                    <a *ngIf="twitter" [href]="twitter" target="_blank"><img title="Follow on Twitter" src="./assets/img/twitter.png" width="17px" alt="Twitter"></a>  
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
           
        </div>
    </div>
    <div class="tuple-footer">
        <span class="flex-row flex-aic left-item" title="">
        </span>
        <div class="tuple-meta fr"><span class="download-status fl" *ngIf="updated">
            <span class="flex-row flex-aic item">Modified on: {{updated}}</span></span>
        </div>
</div>
<ul class="nav nav-tabs navitems" role="tablist">
    <li class="nav-item navtab">
      <a class="nav-link active" id="clickme" data-bs-toggle="tab" href="#home">Profile Details</a>
    </li>
    <!-- <li class="nav-item" >
      <a class="nav-link active" id="clickme" data-bs-toggle="tab" href="#menu1">Profile Details</a>
    </li> -->
</ul>
    <div class="tab-content mt-3">
        <!-- <div id="home" class="tab-pane active cv-attach-section mb-5">
            <div class="skillDiv">Resume
                <span> &nbsp;<a (click)="download(htmlCv)"><em class="fas fa-download download text-primary"></em></a></span>
               
            </div> 
            <div class="observed" *ngIf="htmlCv">
                <iframe id="cv-iframe" title="Attached CV" [srcdoc]="htmlCv" height="1300px" width="115%"></iframe>
            </div>
        </div> -->
        <div id="home" class="tab-pane active cv-attach-section mb-5">
        
            <div class="profilediv">
                <div class="skillDiv">Experience</div>
                <div class="tbldiv">
                    <table class="table table-hover table-striped">
                        <caption [hidden]="true"></caption>
                        <thead>
                          <tr>
                            <th scope="col" class="headcol col-3">Year</th>
                            <th scope="col" class="headcol col-5 text-center">Job Title</th>
                            <th scope="col" class="headcol col-4 text-center">Company</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let skk of response?.groupedWorkExperience">
                            <td class="col-3">{{skk?.startDateOn?.year}}-{{skk?.endDateOn?.year}}</td>
                            <td class="col-5 text-center">{{skk?.positions[0]?.title}}</td>
                            <td class="col-4 text-center">{{skk?.positions[0]?.companyName}}</td>
                          </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="profilediv">
                <div class="skillDiv">Educations</div>
                <div class="tbldiv">
                    <table class="table table-hover table-striped">
                        <caption [hidden]="true"></caption>
                        <thead>
                          <tr>
                            <th scope="col" class="headcol col-3">Year</th>
                            <th scope="col" class="headcol col-5 text-center">Education</th>
                            <th scope="col" class="headcol col-4 text-center">School</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let sk of response?.educations">
                            <td class="col-3">{{sk?.startDateOn?.year}}</td>
                            <td class="col-5 text-center">{{sk?.degreeName || sk?.fieldOfStudy}}</td>
                            <td class="col-4 text-center">{{sk?.schoolName}}</td>
                          </tr>
                        </tbody>
                    </table>
                </div>
            </div>


            <div class="profilediv">
                <div class="skillDiv">Languages</div>
                <div class="tbldiv">
                    <table class="table table-hover table-striped">
                        <caption [hidden]="true"></caption>
                        <thead>
                          <tr>
                            <th scope="col" class="headcol col-3">Language Name</th>
                            <th scope="col" class="headcol col-5 text-center">Proficiency</th>
                            <!-- <th scope="col" class="headcol col-4 text-center">School</th> -->
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let sk of response?.accomplishments?.languages">
                            <td class="col-3">{{sk?.name}}</td>
                            <td class="col-5 text-center">{{sk?.proficiency | titlecase}}</td>
                            <!-- <td class="col-4 text-center">{{sk?.schoolName}}</td> -->
                          </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <div class="profilediv">
                <div class="skillDiv">Skills</div>
                <div class="tbldiv" *ngFor="let sk of response?.profileSkills">
                    <div class="tabletitle">{{sk?.name}}</div>
                    <table class="table table-hover table-light">
                        <caption [hidden]="true"></caption>
                        <thead>
                          <tr>
                            <th scope="col" class="headcol col-7">Name</th>
                            <th scope="col" class="headcol text-center col-3">Last Used</th>
                            <th scope="col" class="headcol text-center col-2">Years Used</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let skk of sk?.skills">
                            <td class="col-7">{{skk?.value}}</td>
                            <td class="col-3 text-center">{{skk?.lastUsed}}</td>
                            <td class="col-2 text-center">{{skk?.yearsUsed}}</td>
                          </tr>
                        </tbody>
                    </table>
                </div>
                <div class="table table_data" [hidden]="skillSummaryDiv">
                    <ul id="horizontal-listhData">
                        <li *ngFor="let skData of skillsummary">{{skData}}</li>
                    </ul>
                </div>
            </div>

             <div class="profilediv">
                <div class="skillDiv">Information</div>
                <div class="work-details">
                    <div class="work-details" *ngIf="disSalary">
                        <div class="detail">
                            <div class="work-title">Desired Salary:</div>
                            <div class="content">{{disSalary}}</div>
                        </div>
                    </div>
                    <div class="work-details" *ngIf="travel">
                        <div class="detail">
                            <div class="work-title">Travel:</div>
                            <div class="content">{{travel}}</div>
                        </div>
                    </div>
                    <div class="work-details" *ngIf="willRelocate">
                        <div class="detail">
                            <div class="work-title">Relocate:</div>
                            <div class="content">{{willRelocate}}</div>
                        </div>
                    </div>
                    <div class="work-details" *ngIf="response?.headline">
                        <div class="detail">
                            <div class="work-title">Most Recent Employer:</div>
                            <div class="content">{{response?.headline}}</div>
                        </div>
                    </div>
                    <div class="work-details" *ngIf="length">
                        <div class="detail">
                            <div class="work-title">Relevant Work Experience:</div>
                            <div class="content">{{length? length +' ' + 'year': 0+' '+'year'}}</div>
                        </div>
                    </div>
                    <div class="work-details" *ngIf="highDeg">
                        <div class="detail">
                            <div class="work-title">Highest Education:</div>
                            <div class="content">{{highDeg}}</div>
                        </div>
                    </div>
                    <div class="work-details" *ngIf="response?.location">
                        <div class="detail">
                            <div class="work-title">Location:</div>
                            <div class="content">{{response?.location?.displayName+','+response?.location?.countryCode}}</div>
                        </div>
                    </div>
                </div>
                <!-- <div class="tbldiv" *ngIf="response?.additionalTabledInformation">
                    <table class="table table-hover table-striped" *ngFor="let addInfo of response?.additionalTabledInformation">
                        <caption [hidden]="true"></caption>
                        <thead>
                          <tr>
                            <th scope="col" class="headcol col-12">{{addInfo?.name}}</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let addsk of addInfo?.values">
                            <td class="col-12">{{addsk}}</td>
                          </tr>
                        </tbody>
                    </table>
                </div> -->
            </div>




        </div>
    </div>
</div>
<ng-http-loader></ng-http-loader>
