import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DiceComponent } from './home/dice/dice.component';
import { IndeedComponent } from './home/indeed/indeed.component';
import { LinkedinComponent } from './home/linkedin/linkedin.component';
import { MainPageComponent } from './home/main-page/main-page.component';
import { MonsterUsComponent } from './home/monster-us/monster-us.component';
import { NaukriComponent } from './home/naukri/naukri.component';
const routes: Routes = [
  { path:'', redirectTo:'home', pathMatch: 'full' },
  {path:'naukri/:urlid',component:NaukriComponent},
  {path:':urlid',component:MainPageComponent},
  {path:'dice/:urlid',component:DiceComponent},
  {path:'monsterus/:urlid',component:MonsterUsComponent},
  {path:'indeed/:urlid',component:IndeedComponent},
  {path:'linkedin/:urlid',component:LinkedinComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { 

}
