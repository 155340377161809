<div [hidden]="naukriPage" class="container-fluid">
    <div class="row content-container">
        <div class="col-sm-12">
            <div class="card details-card">
                <div class="left-section">
                    <div class="profile-tuple">
                        <div class="">
                            <div class="profile flex-row">
                                <div class="left-container">
                                    <div class="profile-avatar">
                                        <div class="candidate-avatar">
                                            <img src="./assets/img/profile.png" alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div class="right-container">
                                    <div class="flex-row">
                                        <div class="details-wrapper">
                                            <div class="candidate-headline flex-row flex-aic">
                                                <span class="name ellipsis" title="">
                                                    <span class="hlite-inherit">
                                                        <span class="hlite-inherit" >
                                                            {{response?.first_name}} {{response?.middle_name}} {{response?.last_name}}
                                                        </span>
                                                        <span class="hlite-inherit" *ngIf="noName">
                                                            {{noName|uppercase}}
                                                        </span>
                                                    </span>
                                                </span>
                                                <button
                                                    class="review-later-btn rdx-action naukri-btn-tertiary naukri-btn-small"
                                                    type="button">
                                                    <div class="naukri-tooltip-wrapper">
                                                        <span title="Edit" (click)="redirectToInSearch()" *ngIf="edit==='true'"><a [href]="rLink" target="_blank"><em class="far fa-edit edit"></em></a></span>
                                                        <span title="{{response?.federated}}" [hidden]="naukri">
                                                            <img class="live-img" src="./assets/img/naukri.png" alt="">
                                                        </span>
                                                        <span title="{{response?.federated}}" [hidden]="dice">
                                                            <img class="live-img" src="./assets/img/dice.png" alt="">
                                                        </span>
                                                        <span title="{{response?.federated}}" [hidden]="monster">
                                                            <img class="live-img" src="./assets/img/monster.png" alt="">
                                                        </span>
                                                        <span title="{{response?.federated}}"  [hidden]="indeed">
                                                            <img class="live-img" src="./assets/img/indeed.png" alt="">
                                                        </span>
                                                        <span title="{{response?.federated}}"  [hidden]="linkedIn">
                                                            <img class="live-img" src="./assets/img/linkedin.png" alt="">
                                                        </span>
                                                        <span title="{{response?.federated}}" [hidden]="unknown">
                                                            <img class="live-img" src="./assets/img/un.png" alt="">
                                                        </span>
                                                        <span title="Local Data">
                                                            <img class="local-img" src="./assets/img/local.png" alt="">
                                                        </span>
                                                       
                                                    </div>
                                                </button>
                                            </div>
                                            <div class="candidate-overview flex-row flex-aic">
                                                <div class="meta-data">
                                                    <em title="Experience"
                                                        class="fa fa-calendar ico ico-work naukri-icon-work"
                                                        aria-hidden="true"></em>
                                                    <!-- <i title="Experience" class="ico ico-work naukri-icon naukri-icon-work"></i> -->
                                                    <span
                                                        title="{{response?.totalExperience}}">{{response?.totalExperience ?? "0y"}}</span>
                                                </div>
                                                <div class="meta-data">
                                                    <!-- <em title="Annual salary"
                                                        class="fa fa-google-wallet ico  naukri-icon-account_balance_wallet"
                                                        aria-hidden="true"></em> -->
                                                    <!-- <i title="Annual salary" class="ico naukri-icon naukri-icon-account_balance_wallet"></i> -->
                                                    <img src="./assets/img/ctc.png" width="16px" alt="">
                                                    <span>&nbsp; ₹{{response?.currentCTC ??  "0.0"}} LPA</span>
                                                </div>
                                                <div class="meta-data">
                                                    <em title="Current location"
                                                        class="fa fa-map-marker-alt ico ico-place naukri-icon-place"
                                                        aria-hidden="true"></em>
                                                    <em title="Current location"
                                                        class="ico ico-place naukri-icon naukri-icon-place"></em>
                                                    <span title="" class="location">
                                                        <span>{{response?.presentCity}}</span> 
                                                    </span>
                                                </div>
                                                <div class="meta-data" title="Willing To Relocate">
                                                    <em class="fa fa-map naukri-icon-place ico-places ico ico-place"></em><span>{{response?.currentWorkLocation}}</span>
                                                </div>
                                                <div class="meta-data" title="Notice Period">
                                                    <em class="fa fa-clock naukri-icon-place ico ico-place"></em><span
                                                        >{{response?.noticePeriod}}</span>
                                                </div>
                                            </div>
                                            <div class="candidate-details">
                                                <div class="current-notice-wrapper flex-row">
                                                    <div class="detail">
                                                        <em class="fa fa-briefcase ico ico-work naukri-icon-work"></em>
                                                        <label>Current</label>
                                                        <span class="ellipsis"
                                                            title="">
                                                            <span class="hlite">
                                                                <span class="hlite-inherit" >{{response.currentDesignation}}</span>
                                                            </span>
                                                            <span class="hlite-inherit" >
                                                                <span class="hlite-inherit text-primary" *> -at </span>
                                                            </span>
                                                            <span class="hlite">
                                                                <span class="hlite-inherit">{{response.currentEmployer}} </span>
                                                            </span>
                                                        </span> <span class="hlite-inherit"><span class="hlite-inherit">
                                                            </span><span >, since
                                                                {{currents.fromYear}}</span></span>
                                                    </div>
                                                    
                                                </div>
                                                <div class="detail pref-loc"><label>
                                                    <i class="fa fa-compass ico ico-work naukri-icon-work" aria-hidden="true"></i>Pref. Locations</label>&nbsp;&nbsp;<span
                                                        class="ellipsis m-l-10"><span
                                                            class="read-more"><span>{{response?.preferredLocation}}&nbsp;</span></span></span>
                                                </div>
                                            </div>

                                            <div class="flex-row flex-aic contact-social">
                                                <div class="contact-wrapper flex-row flex-aic">
                                                    <span>
                                                        <span>
                                                            <i title="phone" class="fa fa-mobile-alt text-muted" aria-hidden="true"></i>&nbsp;&nbsp;&nbsp;<span class="text-muted">Phone:</span>
                                                            <span class="text-primary mrg">{{Candidatedata.mobileNumber ? Candidatedata.mobileNumber : 'Not Available'}}</span>
                                                        </span>
                                                        <span>&nbsp;&nbsp;&nbsp;
                                                            <span class="email"><i title="Email" class="fa fa-envelope text-muted" aria-hidden="true"></i>&nbsp;&nbsp;
                                                                <span class="text-muted">Email:</span>&nbsp;&nbsp;<span>{{Candidatedata.email ?Candidatedata.email : 'Not Available'}}</span>
                                                            </span>
                                                        </span>
                                                    </span>
                                                </div>
                                                <div class="social-media flex-row flex-aife">
                                                    <div class="social-profile flex-row flex-aic">
                                                        <div class="naukri-tooltip-wrapper">
                                                            <span>
                                                                <a [href]="Linkedinurl" target="_blank"  class="linkedin_text"><img src="./assets/img/linkedin.png" width="19px" alt="LinkedIn"></a>&nbsp;
                                                                <a [href]="facebook" target="_blank" *ngIf="facebook"><img title="Follow on Facebook" src="./assets/img/Facebook.png" width="16px" alt="Facebook"></a>&nbsp;
                                                                <a [href]="twitter" target="_blank" *ngIf="twitter"><img title="Follow on Twitter" src="./assets/img/twitter.png" width="18px" alt="Twitter"></a>       
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>

                            </div>

                        </div>

                    </div>

                </div>
            </div>

            <div class="tuple-footer">
                <div class="tuple-meta fr">
                <!-- <span class="download-status fl">
                    <span class="flex-row flex-aic item" *ngIf="response?.html?.lastModified">Modified on: {{response?.html?.lastModified}}</span>
                </span>
                    <span class="flex-row flex-aic item" *ngIf="response?.activeDate">Active: {{response?.activeDate|date}}</span> -->
                </div>
            </div>
            <!-- <div class="tuple-footer">
                <div class="tuple-meta fr">
                <span class="download-status fl">
                    <span class="flex-row flex-aic item" *ngIf="lastModifiedOnNaukri && response.html.naukri.lastModifiedOnNaukri">Modified on: {{response.html.naukri.lastModifiedOnNaukri|date}}</span>
                    <span class="flex-row flex-aic item" *ngIf="lastModifiedOnIndeed && response.html.indeed.lastModifiedOnIndeed">Modified on: {{response.html.indeed.lastModifiedOnIndeed|date}}</span>
                    <span class="flex-row flex-aic item" *ngIf="lastModifiedOnDice && response.html.dice.lastModifiedOnDice">Modified on: {{response.html.dice.lastModifiedOnDice|date}}</span>
                    <span class="flex-row flex-aic item" *ngIf="lastModifiedOnLinkedIn && response.html.linkedIn.lastModifiedOnLinkedIn">Modified on: {{response.html.linkedIn.lastModifiedOnLinkedIn|date}}</span>
                    <span class="flex-row flex-aic item" *ngIf="lastModifiedOnMonster && response.html.monster.lastModifiedOnMonster">Modified on: {{response.html.monster.lastModifiedOnMonster|date}}</span>
                    <span class="flex-row flex-aic item" *ngIf="lastModifiedOnMonsterUS && response.html.monsterUS.lastModifiedOnMonsterUS">Modified on: {{response.html.monsterUS.lastModifiedOnMonsterUS|date}}</span>
                    <span class="flex-row flex-aic item" *ngIf="lastModifiedOnJobDiva && response.html.jobDiva.lastModifiedOnJobDiva">Modified on: {{response.html.jobDiva.lastModifiedOnJobDiva|date}}</span>
                    <span class="flex-row flex-aic item" *ngIf="lastModifiedOnCareerBuilder && response.html.careerBuilder.lastModifiedOnCareerBuilder">Modified on: {{response.html.careerBuilder.lastModifiedOnCareerBuilder|date}}</span>
                    <span class="flex-row flex-aic item" *ngIf="lastModifiedOnJobServe && response.html.jobServe.lastModifiedOnJobServe">Modified on: {{response.html.jobServe.lastModifiedOnJobServe|date}}</span>
                    <span class="flex-row flex-aic item" *ngIf="lastModifiedOnNaukriGulf && response.html.naukriGulf.lastModifiedOnNaukriGulf">Modified on: {{response.html.naukriGulf.lastModifiedOnNaukriGulf|date}}</span>
                    <span class="flex-row flex-aic item" *ngIf="lastModifiedOnOther && response.html.other.lastModifiedOnOther">Modified on: {{response.html.other.lastModifiedOnOther|date}}</span>
                </span>
                    <span class="flex-row flex-aic item">Active: {{response.lastModified|date}}</span>
                </div>
            </div> -->
            
        </div>
        
        <ul class="nav nav-tabs navitems" role="tablist">
            <li class="nav-item navtab">
              <a class="nav-link active" id="clickme1" data-bs-toggle="tab" href="#home">Resume</a>
            </li>
            <li class="nav-item" >
              <a class="nav-link" id="clickme" data-bs-toggle="tab" href="#menu1">Profile Details</a>
            </li>
          </ul>

        <!-- -------------------------------------Tab - 1 ---------------------------------------------------------------------------------------------------------------------------------- -->
        <div class="tab-content mt-3">
            <div id="home" class="tab-pane active cv-attach-section mb-5">
              <div>
                <div class="attach-header">
                    <div class="hd-left">
                        <h4>Attached &nbsp;&nbsp;&nbsp; 
                            <span><a (click)="downloadResume()"><em class="fas fa-download download text-primary"></em></a></span>
                        </h4>
                        <div class="hd-left-content">
                        </div>
                    </div>
                </div>
             
                <div>
                    <ngx-doc-viewer
                        [url]="resume"
                        [viewer]="resExt"
                        style="width:100%;height:100vh;">
                    </ngx-doc-viewer>
                </div>
              </div>
            </div>

        <!-- ------------------------------------Tab - 2 --------------------------------------------------------------------------------------------------------------------------- -->
        <div id="menu1" class="mt-3 tab-pane fade col-sm-12">
                <div class="card profile-summary">
                    <div class="profile-tabs">
                        <div class="naukri-tabs naukri-tabs-top tracker">
                            <div role="tablist" class="naukri-tabs-nav">
                                <div class="naukri-tabs-nav-wrap">
                                    <div class="naukri-tabs-nav-list" style="transform: translate(0px, 0px);">
                                        <div class="naukri-tabs-tab naukri-tabs-tab-active">
                                            <div role="tab" aria-selected="true" class="naukri-tabs-tab-btn" tabindex="0"
                                                id="rc-tabs-0-tab-profile" aria-controls="rc-tabs-0-panel-profile">Profile
                                                detail</div>
                                        </div>
                                        <!-- <div class="naukri-tabs-tab">
                                            <div role="tab" aria-selected="false" class="naukri-tabs-tab-btn"
                                                tabindex="0" id="rc-tabs-0-tab-videoAndCv"
                                                aria-controls="rc-tabs-0-panel-videoAndCv">Attached CV</div>
                                        </div> -->
                                        <div class="naukri-tabs-ink-bar naukri-tabs-ink-bar-animated"
                                            style="left: 28px; width: 85px;"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="profile-content">
                        <div class="profile-width-content">
                            <div class="cv-prev-keyskills">
                                <div class="keyskills-hd">Keyskills</div><span class="keyskills-tag">
                                    <div class="table table_data">
                                        <!-- <ul id="horizontal-list" [hidden]="hideSkills1"> -->
                                            <ul id="horizontal-list">
                                            <li>{{response?.skillSummary}}</li>
                                        </ul>
                                        <!-- <ul id="horizontal-lists"  [hidden]="hideSkills2"> -->
                                            <ul id="horizontal-lists">
                                            <li class="li2" *ngFor="let skData of response?.skills">
                                                <div class="sksk">
                                                    <div class="sk"><span title="{{skData?.name}} ">{{skData?.name}}</span></div>
                                                </div>
                                                <div class="yr">
                                                    <span class="yrs"> {{skData?.experienceTime}}</span>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </span>
                            </div>
                            <div class="work-exp">
                                <h4>Work experience</h4>
                                <div class="career-timeline">
                                </div>
                                <!-- <div class="work-exp-card">
                                    <div class="exp-head">
                                        <div class="exp-icon flex-row flex-aic flex-jcc   exp-icon-even">
                                            <em class="fa fa-briefcase" aria-hidden="true"></em>
                                        </div>
                                        <div class="exp-label">
                                            <div class="desig"><span
                                                    class="hlite"><span>{{currentDesignation}}</span></span><span><span>-
                                                    </span></span> at
                                                <span><span>{{currentEmployer}}</span></span>
                                            </div>
                                            <div class="dates">
                                                <span><span *ngIf="response?.employment?.current?.fromYear">{{response?.employment?.current?.fromYear}} -Till Now</span>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div> -->
                                <div class="work-exp-card" *ngFor="let exp of response?.workexperiences">
                                    <div class="exp-head" *ngIf="exp?.designation  || exp?.organization">
                                        <div class="exp-icon flex-row flex-aic flex-jcc   exp-icon-even">
                                            <em class="fa fa-briefcase" aria-hidden="true"></em>
                                        </div>
                                        <div class="exp-label">
                                            <div class="desig"><span
                                                    class="hlite" *ngIf="exp?.designation"><span>{{exp?.designation}}</span></span><span><span>-
                                                    </span></span>
                                                <span><span *ngIf="exp?.organization"> at {{exp?.organization}}</span></span>
                                            </div>
                                            <div class="dates">
                                                <span><span *ngIf="exp?.fromYear">{{exp?.fromYear}} To </span>
                                                    <span *ngIf="exp?.toYear">{{exp?.toYear}}</span>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="cv-educ" >
                                <h4 class="educ-title" >Education</h4>
                                <div  class="edu-wrapper" *ngFor="let edu of response?.educations" >
                                    <div class="edu-head"  *ngIf="edu?.course">
                                        <div class="edu-icon flex-row flex-aic flex-jcc"><em
                                                class="fa fa-graduation-cap ico ico-info educ-logo  naukri-icon-school-1"></em>
                                        </div>
                                        <div class="edu-label">
                                            <div class="desig"><span *ngIf="edu?.course">{{edu?.course}}</span>
                                                <span *ngIf="edu?.toYear">, {{edu?.toYear}}</span>
                                                <div class="edu-type"><span
                                                        *ngIf="edu?.value">{{edu?.value}}</span></div>
                                            </div>
                                            <div class="dates"><span class="institue"><span class="hlite-inherit"><span
                                                            class="hlite-inherit"></span><span
                                                            *ngIf="edu?.institute">{{edu?.institute}}</span></span></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div class="other-details">
                                <h4 class="hd-other">Other details</h4>
                                <div class="personal-det">
                                    <!-- <h4>Personal details</h4> -->
                                    <div class="table-wrapper details-prev">
                                        <div
                                            style="position: relative; overflow: hidden; width: 100%; height: auto; min-height: 0px; max-height: 100%;">
                                            <div
                                                style="position: relative; overflow: auto; margin-bottom: -17px; min-height: 17px; max-height: calc(100% + 17px);">
                                                <div class="table view-all-table">
                                                    <div class="thead ">
                                                        <div tabindex="0" class="tr tab-row table-tuple table-tuple-0 s">
                                                            <div class="th cell col col-0 ">
                                                                <div class="table-head birth-date">Date of Birth</div>
                                                            </div>
                                                            <div class="th cell col col-1" *ngIf="response?.gender">
                                                                <div class="table-head gender">Gender</div>
                                                            </div>
                                                            <div class="th cell col col-2">
                                                                <div class="table-head marriage">Marital status</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="tbody ">
                                                        <div tabindex="0"
                                                            class="tr tab-row table-tuple table-tuple-1 odd last">
                                                            <div class="td cell col  col-0">
                                                                <div class="table-cell birth-date">{{response?.birthdate |date}}
                                                                </div>
                                                            </div>
                                                            <div class="td cell col  col-1" *ngIf="response?.gender">
                                                                <div class="table-cell gender">{{response?.gender}}</div>
                                                            </div>
                                                            <div class="td cell col  col-2">
                                                                <div class="table-cell marriage">{{response?.maritalStatus}}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="horizontalTrack"
                                                style="position: fixed; height: 6px; bottom: 2px; left: 2px; right: 2px; border-radius: 6px; background-color: rgb(226, 226, 226); visibility: hidden;">
                                                <div class="horizontalThumb"
                                                    style="position: fixed; display: block; height: 6px; bottom: 0px; background-color: rgb(179, 179, 179); border-radius: 6px; width: 0px;">
                                                </div>
                                            </div>
                                            <div
                                                style="position: absolute; width: 6px; right: 2px; bottom: 2px; top: 2px; border-radius: 3px; visibility: hidden;">
                                                <div
                                                    style="position: relative; display: block; width: 100%; cursor: pointer; border-radius: inherit; background-color: rgba(0, 0, 0, 0.2); height: 0px;">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="address">
                                        <div class="adr-title">Contact details</div>
                                        <div class="content">
                                            <span>{{response?.presentAddress}}</span>
                                            <span >, {{response?.presentCity}}</span> 
                                            <span >, {{response?.presentState}}</span> 
                                            <span >, {{response?.countryName}}</span> 
                                            <span>-{{response?.presentZipCode}}.</span>
                                        </div>
                                        <div class="contact">Contact:- <span class="hlite-inherit"><span
                                                    class="hlite-inherit">{{response?.mobileNumber}}</span></span></div>
                                        <div class="email">Email: <span class="hlite-inherit"><span
                                                    class="hlite-inherit">{{response?.email}}</span></span></div>
                                    </div>
                                </div>

                            </div>

                        
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
<div [hidden]="errorPage" style="width: 100%;">
    <app-error-page></app-error-page>
</div>
<!-- <ng-http-loader></ng-http-loader> -->