import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { MainPageComponent } from './home/main-page/main-page.component';
import { NgxDocViewerModule } from 'ngx-doc-viewer';
import { ToastrModule, ToastContainerModule} from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgHttpLoaderModule } from 'ng-http-loader';
import { DiceComponent } from './home/dice/dice.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { ErrorPageComponent } from './home/error-page/error-page.component';
import { MonsterUsComponent } from './home/monster-us/monster-us.component';
import { IndeedComponent } from './home/indeed/indeed.component';
import { NaukriComponent } from './home/naukri/naukri.component';
import { LinkedinComponent } from './home/linkedin/linkedin.component';
import { NgxSpinnerModule } from "ngx-spinner";
@NgModule({
  declarations: [
    AppComponent,
    MainPageComponent,
    DiceComponent,
    ErrorPageComponent,
    MonsterUsComponent,
    IndeedComponent,
    NaukriComponent,
    LinkedinComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    NgxDocViewerModule,
    ToastrModule.forRoot(),
    ToastContainerModule,
    BrowserAnimationsModule,
    NgHttpLoaderModule.forRoot(),
    PdfViewerModule,
    NgxSpinnerModule.forRoot({ type: 'ball-scale-multiple' })
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
