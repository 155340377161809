<section class="page_404">
	<div class="container">
		<div class="row">	
            <div class="col-sm-12 text-center">
                <div class="col-sm-10 col-sm-offset-1  text-center">
                    <div class="four_zero_four_bg">
                        <h1 class="text-center ">404</h1>
                    </div>
                    <div class="contant_box_404">
                        <h3 class="h2">Something went wrong!!</h3>
                        <p>This candidate details not found !</p>
                    </div>
                </div>
            </div>
		</div>
	</div>
</section>