<div class="container-fluid">
    <div class="row content-container">
        <div class="col-sm-12">
            <div class="card details-card">
                <div class="left-section">
                    <div class="profile-tuple">
                        <div class="">
                            <div class="profile flex-row">
                                <div class="left-container">
                                    <div class="profile-avatar">
                                        <div class="candidate-avatar">
                                            <img src="./assets/img/profile.png" alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div class="right-container">
                                    <div class="flex-row">
                                        <div class="details-wrapper">
                                            <div class="candidate-headline flex-row flex-aic">
                                                <span class="name ellipsis" title="{{response?.name}}">
                                                    <span class="hlite-inherit">
                                                        <span class="hlite-inherit">
                                                            {{response?.name}}
                                                        </span>
                                                        <span class="hlite-inherit" *ngIf="noName">
                                                            {{noName|uppercase}}
                                                        </span>
                                                    </span>
                                                </span>
                                                <button
                                                    class="review-later-btn rdx-action naukri-btn-tertiary naukri-btn-small"
                                                    type="button">
                                                    <div class="naukri-tooltip-wrapper">
                                                        <span title="Edit" (click)="redirectToInSearch()" *ngIf="edit==='true'"><a [href]="rLink" target="_blank"><em class="far fa-edit edit"></em></a></span>
                                                        <span title="Naukri Live Data"><img class="live-img"
                                                                src="./assets/img/live1.jpg" alt=""></span>
                                                        
                                                    </div>
                                                </button>
                                            </div>
                                            <div class="candidate-overview flex-row flex-aic">
                                                <div class="meta-data">
                                                    <em title="Experience"
                                                        class="fa fa-briefcase ico ico-work naukri-icon-work"
                                                        aria-hidden="true"></em>
                                                    <!-- <i title="Experience" class="ico ico-work naukri-icon naukri-icon-work"></i> -->
                                                    <span
                                                        title="{{response?.totalExperience}}">{{response?.totalExperience ?? "0y"}}</span>
                                                </div>
                                                <div class="meta-data">
                                                    <img src="./assets/img/ctc.png" width="16px" alt="">
                                                    <span>&nbsp; ₹{{response?.ctc ?? "0.0 Lacs"}}</span>
                                                </div>
                                                <div class="meta-data">
                                                    <em title="Current location"
                                                        class="fa fa-map-marker-alt ico ico-place  naukri-icon-place"
                                                        aria-hidden="true"></em>
                                                    <em title="Current location"
                                                        class="ico ico-place naukri-icon naukri-icon-place"></em>
                                                    <span title="{{response?.city}}"
                                                        class="location">{{response?.city}}</span>
                                                </div>

                                            </div>
                                            <div class="candidate-details">
                                                <div class="current-notice-wrapper flex-row">
                                                    <div class="detail" *ngIf="designation||organization||cstartDate">
                                                        <label>Current</label>
                                                        <span class="ellipsis m-l-10 current_width"
                                                            title="{{designation}} -at {{organization}} since {{cstartDate}}">
                                                            <span class="hlite">
                                                                <span class="hlite-inherit" *ngIf="designation">{{designation}}</span>
                                                            </span>
                                                            <span class="hlite-inherit" >
                                                                <span class="hlite-inherit text-primary" *ngIf="organization"> -at </span>
                                                            </span>
                                                            <span class="hlite">
                                                                <span class="hlite-inherit" *ngIf="organization">{{organization}} </span>
                                                            </span>
                                                        </span> <span class="hlite-inherit"><span class="hlite-inherit">
                                                            </span>&nbsp;&nbsp;<span *ngIf="cstartDate">since
                                                                {{cstartDate}}</span></span>
                                                    </div>
                                                    <div class="detail notice" title="Notice Period" *ngIf="response?.noticePeriod">
                                                        <em class="fa fa-clock ico ico-places"></em><span
                                                            *ngIf="response?.noticePeriod">{{response?.noticePeriod}}</span>
                                                    </div>
                                                </div>
                                                <div class="detail" *ngIf="highDegreeCourse || highDegreeInstitute || highDegreeComYear"><label>Highest degree</label><span
                                                        class="degree_width m-l-10" title=""><span
                                                            *ngIf="highDegreeCourse">{{highDegreeCourse}}</span>
                                                        <span *ngIf="highDegreeInstitute">-
                                                            {{highDegreeInstitute}}</span> <span
                                                            *ngIf="highDegreeComYear">,
                                                            {{highDegreeComYear}}</span></span>
                                                </div>
                                                <div class="detail pref-loc" *ngIf="response?.prefLocation"><label>Pref. Locations</label><span
                                                        class="ellipsis m-l-10"><span
                                                            class="read-more"><span>{{response?.prefLocation}}&nbsp;</span></span></span>
                                                </div>
                                            </div>

                                            <div class="flex-row flex-aic contact-social">
                                                <div class="contact-wrapper flex-row flex-aic">
                                                    <div class="flex-col" *ngIf="response?.mphone">
                                                        <div class="phone-wrapper flex-col flex-aic">
                                                            <div class="flex-row">
                                                                <div class="phone" style="width: 120px;"><button
                                                                        class="candidate-number flex-row flex-aic tel-seen active naukri-btn-secondary naukri-btn-small"
                                                                        type="button" label="Small">
                                                                        <div class="number">{{response?.mphone}}(M)
                                                                        </div>
                                                                    </button></div>
                                                            </div>
                                                            <p class="call-app-text"></p>
                                                        </div>
                                                    </div><em class="pipe naukri-icon naukri-icon-line" *ngIf="response?.mphone"></em>
                                                    <div class="email ellipsis social-media text-danger" *ngIf="response?.email"><em
                                                            title="{{response?.email}}"
                                                            class="ico ico-email naukri-icon naukri-icon-email"></em><span
                                                            class="hlite-inherit"><span
                                                                class="hlite-inherit">{{response?.email}}</span></span>
                                                    </div>
                                                </div>
                                                <div class="social-media flex-row flex-aife">
                                                    <div class="social-profile flex-row flex-aic">
                                                        <div class="naukri-tooltip-wrapper">
                                                            <span *ngIf="Linkedinurl">
                                                                <a [href]="Linkedinurl" target="_blank" class="linkedin_text">
                                                                    <!-- <i class="fa fa-linkedin linkedin_icon  naukri-icon-linkedin"
                                                                        aria-hidden="true"></i> -->
                                                                        <img src="./assets/img/linkedin.png" width="19px" alt="LinkedIn">
                                                                    <span class="media-name text-primary">LinkedIn</span>
                                                                </a>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="career-timeline" *ngIf="response?.educations">
                                <hr class="horizontal-divider">
                                <div class="timeline flex-row">
                                    <div class="naukri-tooltip-wrapper" *ngFor="let campEdu of response?.educations">
                                        <div class="horizontal-line flex-row flex-aic flex-jcc edu even
                                                                hover-style
                                                                no-hover
                                                                " style="width:100%;">
                                            <div class="flex-row flex-aic tooltip-edu"><span
                                                    class="ico-timeline flex-row flex-aic flex-jcc"><em
                                                        class="fa fa-graduation-cap ico  naukri-icon-school"></em></span><span
                                                    class="date ">{{campEdu.yearOfCompletion}}</span></div>
                                        </div>

                                    </div>
                                    
                                    <div class="naukri-tooltip-wrapper">
                                        <div class="horizontal-line flex-row flex-aic flex-jcc even 
                                last-child 
                                hover-style
                                
                                " style="width: 100%"><span class="flex-row flex-aic">
                                                <span class="endDate">till
                                                    date</span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>

                </div>
            </div>

            <div class="tuple-footer"><span class="flex-row flex-aic left-item" title=""><em
                        class="fa fa-eye ico  naukri-icon-remove_red_eye f-14"></em><span>{{response?.numberOfViews}}</span></span>
                <div class="tuple-meta fr"><span class="download-status fl">
                    <span class="flex-row flex-aic item" *ngIf="uploadDates">Modified on: {{uploadDates}}</span></span><span
                        class="flex-row flex-aic item" *ngIf="response?.activeDate">Active: {{response?.activeDate|date}}</span></div>
            </div>
        </div>
        <ul class="nav nav-tabs navitems" role="tablist">
            <li class="nav-item navtab" *ngIf="htmlCv">
              <a class="nav-link active" id="clickme1" data-bs-toggle="tab" href="#home">Resume</a>
            </li>
            <li class="nav-item" >
              <a class="nav-link" id="clickme" data-bs-toggle="tab" href="#menu1">Profile Details</a>
            </li>
          </ul>

        <!-- -------------------------------------Tab - 1 ---------------------------------------------------------------------------------------------------------------------------------- -->
        <div class="tab-content mt-3">
            <div id="home" class="tab-pane active cv-attach-section mb-5">
              <div *ngIf="htmlCv">
                <div class="attach-header">
                    <div class="hd-left">
                        <h4>Attached &nbsp;&nbsp;&nbsp; 
                            <!-- <span *ngIf="resume"><a (click)="downloadResume()"><em class="fas fa-download download text-primary"></em></a></span> -->
                            <span *ngIf="htmlCv">
                                <a (click)="downloadResume()">
                                    <em class="fas fa-download download text-primary"></em>
                                </a> 
                            </span>
                        </h4>
                        <div class="hd-left-content">
                            <div class="date">Last updated on {{uploadDates}}</div>
                        </div>
                    </div>
                </div>
                <!-- <div class="resume_scroll">
                    <kendo-pdf-export #pdf paperSize="A4" margin="0.3cm" [imageResolution]="120" [scale]="0.6"  [repeatHeaders]="true">
                        <div class="observed" *ngIf="htmlCv" >
                            <div [innerHTML]="htmlCv"></div>
                        </div>
                    </kendo-pdf-export>
                </div> -->
                <div class="observed" *ngIf="htmlCv">
                    <iframe id="cv-iframe" title="Attached CV" [srcdoc]="htmlCv" height="1300px" width="115%"></iframe>
                </div>
              </div>
            </div>

        <!-- ------------------------------------Tab - 2 --------------------------------------------------------------------------------------------------------------------------- -->
            <div id="menu1" class="mt-3 tab-pane fade col-sm-12">
                <div class="card profile-summary">
                    <div class="profile-tabs">
                        <div class="naukri-tabs naukri-tabs-top tracker">
                            <div role="tablist" class="naukri-tabs-nav">
                                <div class="naukri-tabs-nav-wrap">
                                    <div class="naukri-tabs-nav-list" style="transform: translate(0px, 0px);">
                                        <div class="naukri-tabs-tab naukri-tabs-tab-active">
                                            <div role="tab" aria-selected="true" class="naukri-tabs-tab-btn" tabindex="0"
                                                id="rc-tabs-0-tab-profile" aria-controls="rc-tabs-0-panel-profile">Profile
                                                detail</div>
                                        </div>
                                        <div class="naukri-tabs-ink-bar naukri-tabs-ink-bar-animated"
                                            style="left: 28px; width: 85px;"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="profile-content">
                        <div class="profile-width-content">
                            <blockquote class="about-cand" *ngIf="response?.jobTitle"><span class="hlite-inherit"><span
                                        class="hlite-inherit">{{response?.jobTitle}}</span></span>
                            </blockquote>
                            <div class="cv-prev-keyskills" *ngIf="response?.displayKeywords || response?.mergedKeySkill">
                                <div class="keyskills-hd">Keyskills</div><span class="keyskills-tag">
                                    <div class="table table_data">

                                        <ul id="horizontal-list" *ngIf="response?.displayKeywords">
                                            <li *ngFor="let skData of skillsDataValues">{{skData}}</li>
                                        </ul>

                                    </div>
                                </span>
                                <!-- <a class="link int view-it-skills" href="/">View IT skills</a> -->
                                <div class="mayKnow-cont">
                                    <div class="mayKnow" *ngIf="response?.mergedKeySkill">May also know</div><span class="keyskills-tag">

                                        <div class="table table_data">
                                            <ul id="horizontal-list">
                                                <li *ngFor="let skData of otherSkillData">{{skData}}</li>
                                            </ul>
                                        </div>
                                    </span>
                                </div>
                            </div>

                            <div class="work-summary">
                                <h4 *ngIf="workSummary">Work summary</h4>
                                <div class="summary-lines"><span class="hlite-inherit"><span
                                            class="hlite-inherit">{{workSummary}}</span></span>
                                </div>
                                <div class="work-details">
                                    <div class="detail" *ngIf="response?.industryType">
                                        <div class="work-title">Industry</div>
                                        <div class="content">{{response?.industryType}}</div>
                                    </div>
                                    <div class="detail" *ngIf="response?.farea">
                                        <div class="work-title">Functional Area</div>
                                        <div class="content">{{response?.farea}}</div>
                                    </div>
                                    <div class="detail" *ngIf="response?.role">
                                        <div class="work-title">Role</div>
                                        <div class="content">{{response?.role}}</div>
                                    </div>
                                </div>
                            </div>

                            <div class="work-exp" *ngIf="response?.workExperiences">
                                <h4>Work experience</h4>
                                <div class="career-timeline">
                                    <div class="timeline flex-row">
                                        <div class="naukri-tooltip-wrapper" *ngFor="let exp of datas">
                                            <div class="horizontal-line flex-row flex-aic flex-jcc last-child even hover-style"
                                                style="width: 100%;">
                                                <span class="flex-row flex-aic">
                                                    <span class="ico-timeline-ellipse">
                                                    </span>
                                                    <span class="date no-icon even">{{exp.startDate}}</span>
                                                    
                                                </span>
                                            </div>
                                        </div>
                                        <div class="naukri-tooltip-wrapper ">
                                            <div class="horizontal-line flex-row flex-aic flex-jcc last-child even
                                                hover-style" style="width: 100%"><span class="flex-row flex-aic">
                                                    <span class="endDate">till
                                                        date</span></span>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div class="work-exp-card" *ngFor="let exp of t">
                                    <div class="exp-head">
                                        <div class="exp-icon flex-row flex-aic flex-jcc   exp-icon-even">
                                            <em class="fa fa-briefcase" aria-hidden="true"></em>
                                        </div>
                                        <div class="exp-label">
                                            <div class="desig"><span
                                                    class="hlite"><span>{{exp?.designation}}</span></span><span><span>-
                                                    </span></span> at
                                                <span><span>{{exp?.organization}}</span></span>
                                            </div>
                                            <div class="dates">
                                                <span><span *ngIf="exp?.startDate">{{exp?.startDate}} till</span>
                                                    {{exp?.endDate}} <span *ngIf="exp?.yearOfExp">({{exp?.yearOfExp}})</span>

                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="desc">
                                        <span>
                                            <span>{{exp?.profile}} </span>
                                        </span>

                                    </div>
                                </div>
                            </div>

                            <div class="cv-educ">
                                <h4 class="educ-title" *ngIf="response?.educations">Education</h4>
                                <div class="edu-wrapper" *ngFor="let edu of response?.educations">
                                    <div class="edu-head">
                                        <div class="edu-icon flex-row flex-aic flex-jcc"><em
                                                class="fa fa-graduation-cap ico ico-info educ-logo  naukri-icon-school-1"></em>
                                        </div>
                                        <div class="edu-label">
                                            <div class="desig"><span *ngIf="edu.course">{{edu?.course.label}},</span><span
                                                    *ngIf="edu?.spec">{{edu?.spec?.label}},</span><span
                                                    *ngIf="edu?.yearOfCompletion">&nbsp; {{edu?.yearOfCompletion}}</span>
                                                <div class="edu-type"><span
                                                        *ngIf="edu?.educationType">{{edu?.educationType?.label}}</span></div>
                                            </div>
                                            <div class="dates"><span class="institue"><span class="hlite-inherit"><span
                                                            class="hlite-inherit"></span><span
                                                            *ngIf="edu?.institute">{{edu?.institute?.label}}</span></span></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div id="cv-prev-it-skills" class="cv-prev-it-skills" *ngIf="response?.skills">
                                <h4>IT skills</h4>
                                <div class="table-wrapper">
                                    <div
                                        style="position: relative; overflow: hidden; width: 100%; height: auto; min-height: 0px; max-height: 100%;">
                                        <div
                                            style="position: relative; overflow: scroll; margin-right: -17px; margin-bottom: -17px; min-height: 17px; max-height: calc(100% + 17px);">
                                            <div class="table view-all-table">
                                                <div class="thead ">
                                                    <div tabindex="0" class="tr tab-row table-tuple table-tuple-0 s">
                                                        <div class="th cell col col-0 ">
                                                            <div class="head-cell Skills-hd">Skills</div>
                                                        </div>
                                                        <div class="th cell col col-1 ">
                                                            <div class="head-cell Version-hd">Version</div>
                                                        </div>
                                                        <div class="th cell col col-2 ">
                                                            <div class="head-cell Last Used-hd">Last Used</div>
                                                        </div>
                                                        <div class="th cell col col-3 last">
                                                            <div class="head-cell Experience-hd">Experience</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="tbody ">
                                                    <div tabindex="0" class="tr tab-row table-tuple table-tuple-1 odd last"
                                                        *ngFor="let itskill of response?.skills">
                                                        <div class="td cell col  col-0">
                                                            <div class="data-cell skills">
                                                                <span><span>{{itskill?.skill?.label}}</span></span></div>
                                                        </div>
                                                        <div class="td cell col  col-1">
                                                            <div>{{itskill?.version}}</div>
                                                        </div>
                                                        <div class="td cell col  col-2">
                                                            <div>{{itskill?.lastUsed}}</div>
                                                        </div>
                                                        <div class="td cell col last col-3">
                                                            <div>{{itskill?.experienceTimeLable}}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="horizontalTrack"
                                            style="position: fixed; height: 6px; bottom: 2px; left: 2px; right: 2px; border-radius: 6px; background-color: rgb(226, 226, 226);">
                                            <div class="horizontalThumb"
                                                style="position: fixed; display: block; height: 6px; bottom: 0px; background-color: rgb(179, 179, 179); border-radius: 6px; width: 0px;">
                                            </div>
                                        </div>
                                        <div
                                            style="position: absolute; width: 6px; right: 2px; bottom: 2px; top: 2px; border-radius: 3px;">
                                            <div
                                                style="position: relative; display: block; width: 100%; cursor: pointer; border-radius: inherit; background-color: rgba(0, 0, 0, 0.2); height: 0px;">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="online-pres-cand" *ngIf="response?.workSampleLinks?.title">
                                <h4 class="title">Online presence</h4>
                                <div class="section work-sample">
                                    <h3>Work sample</h3>
                                    <!-- <span *ngIf="!response?.workSampleLinks" style="font-size:15px ;">No Record
                                        Here...</span> -->
                                    <div class="card-cont">
                                        <div class="online-pres-card" *ngFor="let sam of response?.workSampleLinks">
                                            <div class="online-pres-head">
                                                <div class="online-pres-text">{{sam?.title}}</div>
                                                <div class="online-pres-label">{{sam?.startDate}}</div>
                                            </div><a target="_blank" [href]="sam?.url"
                                                class="link ext link">{{sam?.url}}</a><span class="read-more">
                                                <div class="role-desc online-pres-text line-clamp">{{sam?.description}}
                                                </div>

                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="other-details">
                                <h4 class="hd-other">Other details</h4>
                                <div class="languages" *ngIf="response?.languages">
                                    <h4>Languages known</h4>
                                    <div *ngFor="let lan of response?.languages">
                                        <div class="lang">{{lan?.lang}}
                                            <span class="proficiency"><span *ngIf="lan?.ability">
                                                    ({{lan?.ability}})</span></span>
                                        </div>
                                    </div>
                                    
                                </div>
                                <div class="personal-det">
                                    <h4>Personal details</h4>
                                    <div class="table-wrapper details-prev">
                                        <div
                                            style="position: relative; overflow: hidden; width: 100%; height: auto; min-height: 0px; max-height: 100%;">
                                            <div
                                                style="position: relative; overflow: auto; margin-bottom: -17px; min-height: 17px; max-height: calc(100% + 17px);">
                                                <div class="table view-all-table">
                                                    <div class="thead ">
                                                        <div tabindex="0" class="tr tab-row table-tuple table-tuple-0 s">
                                                            <div class="th cell col col-0 " *ngIf="response?.birthDate">
                                                                <div class="table-head birth-date">Date of Birth</div>
                                                            </div>
                                                            <div class="th cell col col-1" *ngIf="response?.gender">
                                                                <div class="table-head gender">Gender</div>
                                                            </div>
                                                            <div class="th cell col col-2" *ngIf="response?.maritalStatus">
                                                                <div class="table-head marriage">Marital status</div>
                                                            </div>
                                                            <div class="th cell col col-3 last"  *ngIf="response?.disabled">
                                                                <div class="table-head disability">Physically Challenged
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="tbody ">
                                                        <div tabindex="0"
                                                            class="tr tab-row table-tuple table-tuple-1 odd last">
                                                            <div class="td cell col  col-0" *ngIf="response?.birthDate">
                                                                <div class="table-cell birth-date">{{response?.birthDate}}
                                                                </div>
                                                            </div>
                                                            <div class="td cell col  col-1" *ngIf="response?.gender">
                                                                <div class="table-cell gender">{{response?.gender}}</div>
                                                            </div>
                                                            <div class="td cell col  col-2" *ngIf="response?.maritalStatus">
                                                                <div class="table-cell marriage">{{response?.maritalStatus}}
                                                                </div>
                                                            </div>
                                                            <div class="td cell col last col-3" *ngIf="response?.disabled">
                                                                <div class="table-cell disability">{{response?.disabled}}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="horizontalTrack"
                                                style="position: fixed; height: 6px; bottom: 2px; left: 2px; right: 2px; border-radius: 6px; background-color: rgb(226, 226, 226); visibility: hidden;">
                                                <div class="horizontalThumb"
                                                    style="position: fixed; display: block; height: 6px; bottom: 0px; background-color: rgb(179, 179, 179); border-radius: 6px; width: 0px;">
                                                </div>
                                            </div>
                                            <div
                                                style="position: absolute; width: 6px; right: 2px; bottom: 2px; top: 2px; border-radius: 3px; visibility: hidden;">
                                                <div
                                                    style="position: relative; display: block; width: 100%; cursor: pointer; border-radius: inherit; background-color: rgba(0, 0, 0, 0.2); height: 0px;">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="address">
                                        <div class="adr-title">Contact details</div>
                                        <div class="content">
                                            <span *ngIf="response?.contactAddress">{{response?.contactAddress}},</span>{{response?.mailCity}}<span *ngIf="response?.pin">-{{response?.pin}}</span>
                                        </div>
                                        <div class="contact">Contact:- <span class="hlite-inherit"><span
                                                    class="hlite-inherit">{{response?.mphone}}</span></span></div>
                                        <div class="email">Email: <span class="hlite-inherit"><span
                                                    class="hlite-inherit">{{response?.email}}</span></span></div>
                                    </div>
                                </div>
                                <div class="desired-job" *ngIf="response?.jobType ||response?.empStatus">
                                    <h4>Desired job detail</h4>
                                    <div class="table-wrapper details-prev">
                                        <div
                                            style="position: relative; overflow: hidden; width: 100%; height: auto; min-height: 0px; max-height: 100%;">
                                            <div
                                                style="position: relative; overflow: scroll; margin-bottom: -17px; min-height: 17px; max-height: calc(100% + 17px);">
                                                <div class="table view-all-table">
                                                    <div class="thead ">
                                                        <div tabindex="0" class="tr tab-row table-tuple table-tuple-0 s">
                                                            <div class="th cell col col-0 " *ngIf="response?.jobType">
                                                                <div class="table-head job-type">Job Type</div>
                                                            </div>
                                                            <div class="th cell col col-1 last" *ngIf="response?.empStatus">
                                                                <div class="table-head emp-status">Employment status
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="tbody ">
                                                        <div tabindex="0"
                                                            class="tr tab-row table-tuple table-tuple-1 odd last">
                                                            <div class="td cell col  col-0" *ngIf="response?.jobType">
                                                                <div class="table-cell job-type">{{response?.jobType}}</div>
                                                            </div>
                                                            <div class="td cell col last col-1" *ngIf="response?.empStatus">
                                                                <div class="table-cell emp-status">{{response?.empStatus}}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="horizontalTrack"
                                                style="position: fixed; height: 6px; bottom: 2px; left: 2px; right: 2px; border-radius: 6px; background-color: rgb(226, 226, 226); visibility: hidden;">
                                                <div class="horizontalThumb"
                                                    style="position: fixed; display: block; height: 6px; bottom: 0px; background-color: rgb(179, 179, 179); border-radius: 6px; width: 0px;">
                                                </div>
                                            </div>
                                            <div
                                                style="position: absolute; width: 6px; right: 2px; bottom: 2px; top: 2px; border-radius: 3px; visibility: hidden;">
                                                <div
                                                    style="position: relative; display: block; width: 100%; cursor: pointer; border-radius: inherit; background-color: rgba(0, 0, 0, 0.2); height: 0px;">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="work-auth" *ngIf="response?.workStatusOther">
                                    <h4>Work authorization</h4>
                                    <div class="table-wrapper details-prev">
                                        <div
                                            style="position: relative; overflow: hidden; width: 100%; height: auto; min-height: 0px; max-height: 100%;">
                                            <div
                                                style="position: relative; overflow: scroll; margin-right: 0px; margin-bottom: -17px; min-height: 17px; max-height: calc(100% + 17px);">
                                                <div class="table view-all-table">
                                                    <div class="thead ">
                                                        <div tabindex="0" class="tr tab-row table-tuple table-tuple-0 s">
                                                            <div class="th cell col col-0 last">
                                                                <div class="table-head work-stat-other">Other Countries
                                                                    status</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="tbody ">
                                                        <div tabindex="0"
                                                            class="tr tab-row table-tuple table-tuple-1 odd last">
                                                            <div class="td cell col last col-0">
                                                                <div class="table-cell work-stat-other">
                                                                    {{response?.workStatusOther}}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="horizontalTrack"
                                                style="position: fixed; height: 6px; bottom: 2px; left: 2px; right: 2px; border-radius: 6px; background-color: rgb(226, 226, 226); visibility: hidden;">
                                                <div class="horizontalThumb"
                                                    style="position: fixed; display: block; height: 6px; bottom: 0px; background-color: rgb(179, 179, 179); border-radius: 6px; width: 0px;">
                                                </div>
                                            </div>
                                            <div
                                                style="position: absolute; width: 6px; right: 2px; bottom: 2px; top: 2px; border-radius: 3px; visibility: hidden;">
                                                <div
                                                    style="position: relative; display: block; width: 100%; cursor: pointer; border-radius: inherit; background-color: rgba(0, 0, 0, 0.2); height: 0px;">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<ng-http-loader></ng-http-loader>