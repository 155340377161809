import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { ApicallService } from 'src/app/service/apicall.service';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-naukri',
  templateUrl: './naukri.component.html',
  styleUrls: ['./naukri.component.css']
})
export class NaukriComponent implements OnInit {

  jasonData: any;
  response: any=[];
  workstart: any=[];
  endDate: any;
  datas: any=[];
  endDates: any ;
  keyskill: any;

  skillsData: any=[];
  skillsDataValues: any=[];
  otherSkill: any;
  otherSkills: any=[];
  otherSkillData: any=[];
  currentData: any;

  currentDatas: any=[];
  datacurrentData: any=[];
  designation: any;
  organization: any;
  cstartDate: any;

  noticePeriod: any;
  highDegreeCourse: any;
  highDegreeInstitute: any;
  highDegreeComYear: any;

  uploadDates: any;
  workSummary: any;
  d1: any;
  d2: any;
  textCv: any;

  finalDate: any;
  bigData: any=[];
  tt: any;
  bigDataSss: any=[];

  t: any;
  object: any;
  asdasdasd: any;
  urlid:any;
  textCv1: any;
  Linkedinurl: any;
  dd: any;
  data: unknown;
  Linkedin: any;
  resumeView: any;
  resumeViewData: any;
  htmlCv: any;
  resume: any;
  office:any="office";
  resumeName: any;
  resExt: any="";
  canName: any;
  edu: any=[];
  resumExt: any=[];
  canEmail: any;
  noName: any;
  LinkedinLink: any;
  profileNameCheck: any;
  base64code:any
  rLink: any;
  federated="naukriindia";
  navigationId: any;
  edit:any
  constructor(
    private toastr: ToastrService,
    private apicallService:ApicallService,
    private http:HttpClient, private _route: ActivatedRoute, 
    private domSanitizer: DomSanitizer,
    private titleService:Title,
    private router: Router,
    ) { }

  ngOnInit(): void {
        this._route.paramMap.subscribe(params => {
          this.urlid = params.get('urlid');
          // console.log(this.urlid );
          });
          localStorage.setItem('federated',this.federated);
          this.getDatas();
  }
// -----------------------Json Data From Live----------------------------------------------------------------------
  jsonData(){
    // debugger
    return new Promise((resolve) => {
    this.apicallService.getJsonData(this.urlid).then((data: any) => {
      if (data.status===0 || data.status===403 || data.name==="HttpErrorResponse") {
        localStorage.setItem('federated',this.federated);
        this.router.navigate([`./${this.urlid}`]);
      } else {
        if(data !=null || data !=undefined){
        //   this.resumeViewData=data?.textCv;
        // if(this.resumeViewData !=null || this.resumeViewData !=undefined){
        //   this.htmlCv=this.domSanitizer.bypassSecurityTrustHtml(this.resumeViewData);
        //   console.log("RESUME",this.htmlCv);
        // }
      //   if(this.htmlCv ===undefined ){
      //     var click=document.getElementById('clickme');
      //     click?.click()
      // }
      const email=data.email
      if(email !=undefined){
      this.navigationId=btoa(email)
      this.edit="true"
      }
      else{
        this.edit="false"
      }
          resolve(data)
        }else{
          localStorage.setItem('federated',this.federated);
          this.router.navigate([`./${this.urlid}`]);
        }
      }
    })
  }); 
  }
// ------------------------------------ Resume Data From Live -----------------------------------------------------
  resumeData(){
    // this.resumeView=this.apicallService.getResumeData(this.urlid);
    this.apicallService.getResumeData(this.urlid).then((data:any) =>{
    if (data.status !=0 || data.status !=403 || data.name !="HttpErrorResponse") {
      this.resumeViewData=data?.htmlCv;
      if(this.resumeViewData !=null || this.resumeViewData !=undefined){
        this.htmlCv=this.domSanitizer.bypassSecurityTrustHtml(this.resumeViewData);
      }
      if(this.htmlCv ===undefined ){
        var click=document.getElementById('clickme');
        click?.click()
    }
    }else{
      data=null;
    }
    })
  }

async getDatas(){  
  // debugger
    this.data = await this.jsonData();
    this.resumeData();
      this.response=this.data;
      if(this.response===null || this.response===undefined){
        localStorage.setItem('federated',this.federated);
        this.router.navigate([`./${this.urlid}`]);
      }
    // console.log("RES",this.response)

    // ------------------- Title -------------------
   this.canName=this.response?.name;
   if(this.response !=null || this.response !=undefined){
    this.titleService.setTitle(this.canName);
   }
   if(this.response?.name===null || this.response?.name===undefined){
    this.titleService.setTitle("Profile");
   }
  //  console.log("NAME", this.canName)
    // ----------------------------- Title End ---------------------
    if(this.response?.email !=null || this.response?.email !=undefined){
      if(this.response?.name === null || this.response?.name ===undefined){
        this.canEmail=this.response?.email;
        var noNam=this.canEmail.split("@");
        this.noName=noNam[0];
        // console.log("NOOOOONAME",this.noName)
      }
    }

// ----------------------Resume View----------------------------------------------
if(this.response?.resumeName !=null){
    this.resumeName=this.response?.resumeName;
    var resName=this.resumeName.split(".")
    resName.forEach((data:any)=>{
      this.resumExt.push(data)
    })
    var nemeExtention=this.resumExt.length-1
    this.resumeName=resName[nemeExtention];
    // console.log(this.resumeName)
    if(this.resumeName=="pdf"){
      this.resExt="url";
    }else{
      this.resExt="office";
    }
    // this.resume=this.response?.resume;
    // console.log("RSM",this.resume);
  }

    // console.log("RESUME",this.resume);
// ----------------------End Resume View----------------------------------------------

// ----------------------LinkedIn----------------------------------------------
    
    if(this.response?.onlineProfileLinks !=null){
      this.response?.onlineProfileLinks.forEach((link:any)=>{
        if(link?.url !=null || link?.url !=undefined || link?.url !=""){
          if(link?.url.includes("linkedin.com")===true){
          this.LinkedinLink=link;
          // console.log(link);
          }
        }
      })
          if(this.LinkedinLink?.url !=null){
            var linkId=this.LinkedinLink?.url;
            var linkFistName=this.response?.name;
           var fullName= linkFistName.split(" ");
           var fName=fullName[0];
           var seachName=fName.toLowerCase(fName)
            if(linkId.includes(seachName) ===true){
             var profileCheck=this.LinkedinLink.url;
               this.profileNameCheck=profileCheck.split("?");
               
              if(this.profileNameCheck[0].includes("//") ===true){
                this.Linkedinurl=this.profileNameCheck[0];
                // console.log("LinkIn-URL1",this.Linkedinurl);
              }else{
                this.Linkedinurl="//"+this.profileNameCheck[0];
                // console.log("LinkIn-URL2",this.Linkedinurl);
              }
            }
        }
    }
// ----------------------End LinkedIn----------------------------------------------


// ----------------------Work Experience----------------------------------------------
    if(this.response?.workExperiences !=null){
      this.workstart=this.response?.workExperiences;
      if(this.response?.workExperiences[0] !=null){
        if(this.response?.workExperiences[0]?.designation !=null){
          this.designation=this.response?.workExperiences[0]?.designation;
        }
       if(this.response?.workExperiences[0]?.organization !=null){
        this.organization=this.response?.workExperiences[0]?.organization;
       }
        if(this.response?.workExperiences[0]?.startDate !=null){
          this.cstartDate=this.response?.workExperiences[0]?.startDate;
        }
        if(this.response?.workExperiences[0]?.profile !=null){
          this.workSummary=this.response?.workExperiences[0]?.profile;
        }
      }    
    this.t = this.workstart.map((el:any) => {
      this.object = Object.assign({}, el);
      this.object.yearOfExp = null;
      return this.object;
      })
      this.workstart.forEach((arr:any)=>{
        this.d1=arr?.startDate;
        this.d2=arr?.endDate;
        // console.log("d2",this.d1)

        if(this.d1 !=undefined && this.d2!=undefined){
      if( this.d2=="till date"){
          this.tt=new Date();
          var cccc=this.tt.toUTCString();
          var dd=cccc.substring(8,16)   
            var re="'";
            var cc=this.d1.replace(re,"20");
            var date1 = new Date(cc); 
            var date2 = new Date(dd); 
            // console.log("DATE1",date1);
            // console.log("DATE2",date2);
            var Time = date2.getTime() - date1.getTime(); 
            var days =Math.floor( Time / (1000 * 3600 * 24));
            var months = Math.ceil(days/31)+1;
            var resMonth = (months%12);
            var years = Math.floor(months/12);
            this.finalDate=years + "y " + resMonth + "m";
            arr.yearOfExp=this.finalDate;           
        }else{
          // debugger
            var re="'";
            var cc=this.d1.replace(re,"20");
            var re="'";
            var dd=this.d2.replace(re,"20");
            var date1 = new Date(cc); 
            var date2 = new Date(dd); 
            var Time = date2.getTime() - date1.getTime(); 
            var days =Math.floor( Time / (1000 * 3600 * 24));
            var months = Math.ceil(days/31)+1;
            var resMonth =(months%12);
            var years = Math.floor(months/12);
            this.finalDate=years + "y " + resMonth + "m";
            // console.log("FDATE",this.finalDate);
              arr.yearOfExp=this.finalDate;
        }     
        this.bigData.push(arr)
      }else{
        this.t=this.workstart;
      }
      })
      if(this.d1 !=undefined && this.d2!=undefined){
        this.t=this.bigData;
      }
      // console.log("NEW",this.t);
    }
// ----------------------End Work Experience----------------------------------------------

// ----------------------Education----------------------------------------------
    if(this.response?.educations !=null){
     this.response?.educations.forEach((data:any)=>{
        this.edu.push(data)
      })
      var eee=this.edu.length-1;
      // console.log("EDUCATION",eee)
      if(this.response?.educations[eee] !=null){
      //  if( this.response?.educations[eee]?.course){
        if(this.response?.educations[eee]?.course?.label !=null){
          this.highDegreeCourse=this.response?.educations[eee]?.course?.label;
        }
      //  }
      //  if( this.response?.educations[eee]?.institute){ 
        if(this.response?.educations[eee]?.institute?.label !=null){
          this.highDegreeInstitute=this.response?.educations[eee]?.institute?.label;
        }
      //  }
       if(this.response?.educations[eee]?.yearOfCompletion !=null){
        this.highDegreeComYear=this.response?.educations[eee]?.yearOfCompletion;
       }
      }
    // console.log("AAAAAAAA",this.highDegreeCourse);

    }
// ----------------------End Education----------------------------------------------


// ----------------------Upload Date----------------------------------------------
    if(this.response?.fssResponse !=null){
    this.uploadDates=this.response?.fssResponse?.uploadDate;
    }
// ---------------------End-Upload Date----------------------------------------------

    if(this.response?.workExperiences !=null){
      this.workstart.slice().reverse().forEach((element: any)=> {
        this.datas.push(element);
      });
    }
    // ------------------- Skills -------------------

    if(this.response?.displayKeywords  !=undefined || this.response?.displayKeywords  !=null){
      this.keyskill=this.response?.displayKeywords;
      this.skillsDataValues=this.keyskill.split(",");
      this.skillsData.forEach((ele: any)=> {
        this.skillsDataValues.push(ele);
      });
    // console.log("dgdf",this.skillsDataValues);
    }
    // ------------------- End Skills-------------------

    // ------------------- Other Skills-------------------

    if(this.response?.mergedKeySkill !=undefined ||this.response?.mergedKeySkill !=null){
      this.otherSkill=this.response?.mergedKeySkill; 
    this.otherSkillData=this.otherSkill.split(",");
    this.otherSkills.forEach((ele: any)=> {
      this.otherSkillData.push(ele);
    });
    }  

  }

  // ---------------------------- Re-Direct to Internal Search-----------------------------------------------------
  redirectToInSearch(){
    this.rLink=`${environment.iSearch}/#/InternalSearch/candidateProfileView/${this.navigationId}?data=primary`;
  }
  // ---------------------------- End Re-Direct to Internal Search-----------------------------------------------------

  // ------------------------- Download Resume ------------------------------------------------------------------------
  async downloadResume() {
    let blob;
    await this.apicallService.getResumeDowload(this.urlid).then(data => {
      if (data.data !== null) {
        this.base64code = data?.data?.response?.resume_data;
        const ext = (data?.data?.response?.resumeName).split('.')[1]

        if (this.base64code === 'not found.') {
          alert('Resume Not Found!');
        }
        else {
          const byteArray = new Uint8Array(atob(this.base64code).split('').map(char => char.charCodeAt(0)));
            if (ext === 'doc' || ext === 'pdf' || ext === 'docx') {
            let a = document.createElement("a");
            if(this.canName === undefined || this.canName === null){
              this.canName="profile"
            }
            if(ext === 'doc'){
              // a.href = URL.createObjectURL( blob = new Blob([byteArray], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' }));
              a.href = URL.createObjectURL( blob = new Blob([byteArray], { type: 'application/msword' }));
              a.download = `${this.canName}`
              a.click();
              this.toastr.success('Download Successfully');  
              
            }
            else if(ext === 'pdf'){
              a.href = URL.createObjectURL( blob = new Blob([byteArray],  { type: 'application/pdf' }));
              a.download = `${this.canName}`
              a.click();
              this.toastr.success('Download Successfully');            
            }
            else if(ext === 'docx'){
              // a.href = URL.createObjectURL( blob = new Blob([byteArray], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' }));
              a.href = URL.createObjectURL( blob = new Blob([byteArray], { type: 'application/msword' }));
              
              a.download = `${this.canName}`
              a.click();
              this.toastr.success('Download Successfully');
            }
          }
        }
      }
      else {
        this.toastr.warning('Resume Not Found!');
      }
    });
  }
  // download(htmlCv:any){
  //   let printContents = htmlCv?.changingThisBreaksApplicationSecurity;
  //   let originalContents = document.body.innerHTML;
  //   document.body.innerHTML = printContents; 
  //   window.print();
  //   document.body.innerHTML = originalContents;
  //   window.location.reload();
  // }
  // ------------------------- End Download Resume ------------------------------------------------------------------------
}
