import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl, Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ApicallService } from 'src/app/service/apicall.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-indeed',
  templateUrl: './indeed.component.html',
  styleUrls: ['./indeed.component.css']
})
export class IndeedComponent implements OnInit {
  urlid: any;
  data: any;
  response: any = [];
  html: any;
  rLink: any;
  canName: any;
  federated='indeed';
  constructor(
    private _route: ActivatedRoute,
    private apicallService: ApicallService,
    private http: HttpClient,
    private domSanitizer: DomSanitizer,
    private titleService: Title,
    private router: Router

  ) { }
  indeedJson() {
    return new Promise((resolve) => {
      this.apicallService.indeedJson(this.urlid).then((data: any) => {
        if (data.status === 0 || data.status === 403 || data.name === "HttpErrorResponse") {
          localStorage.setItem('federated',this.federated);
          this.router.navigate([`./${this.urlid}`]);        
        } else {
          if (data != null || data != undefined) {
            resolve(data)
          } else {
            localStorage.setItem('federated',this.federated);
            this.router.navigate([`./${this.urlid}`]);
          }
        }
      })
    });
  }
  ngOnInit(): void {
    this._route.paramMap.subscribe(params => {
      this.urlid = params.get('urlid');
    });
    this.getDatas();
  }
  async getDatas() {
    this.data = await this.indeedJson();
    this.response = this.data;
    if (this.response === null || this.response === undefined) {
      localStorage.setItem('federated',this.federated);
      this.router.navigate([`./${this.urlid}`]);
    }
    this.canName = this.response?.resumes[0]?.fullName;
    if (this.response?.resumes[0] != null || this.response?.resumes[0] != undefined) {
      this.titleService.setTitle(this.canName);
    }
    if (this.response?.resumes[0]?.fullName === null || this.response?.resumes[0]?.fullName === undefined) {
      this.titleService.setTitle("Profile");
    }
    if (this.response) {
      this.html = this.domSanitizer.bypassSecurityTrustHtml(this.response?.resumes[0]?.html);
    }
    // console.log("adsd",this.html);
  }
  redirectToInSearch() {
    this.rLink = `${environment.iSearch}/#/InternalSearch/candidateProfileView/${this.urlid}?data=primary`;
  }
}
