import { Injectable  } from "@angular/core";
import { Router } from "@angular/router";
import { EnvService } from "./env.service";
import {
  HttpHeaders,
  HttpClient
} from "@angular/common/http";
@Injectable({
  providedIn: "root"
})
export class ApiService {
  apiRoot: any;
 
  constructor(
    private readonly _HttpClient: HttpClient,
    private readonly _Router: Router,
    private readonly _EnvService: EnvService,
  ) {
    if (_EnvService.enableDebug) {
      this.apiRoot = this._EnvService.apiUrl;
    }
  }

  postRequest(url: string, body: any, headers?: HttpHeaders) {
    let request;
    if (headers) {
      request = this._HttpClient.post(this.apiRoot + url, body, { headers });
    } else {
      request = this._HttpClient.post(this.apiRoot + url, body);
    }
    return request
      .toPromise()
      .then(res => {
        // alert(JSON.stringify(res))
        return res;
      })
      .catch(err => {
        return err;
        
      });
  }
  getRequest(url: string, headers?: HttpHeaders) {
    let request;
    if (headers) {
      request = this._HttpClient.get(this.apiRoot + url, { headers });
    } else {
      request = this._HttpClient.get(this.apiRoot + url);
    }
    return request
      .toPromise()
      .then(res => {
        return res;
      })
      .catch(err => {
        return err;
        
      });
  }
  putRequest(url: string, body: any, headers?: HttpHeaders) {
    let request;
    if (headers) {
      request = this._HttpClient.put(this.apiRoot + url, body, { headers });
    } else {
      request = this._HttpClient.put(this.apiRoot + url, body);
    }
    return request
      .toPromise()
      .then(res => {
        return res;
      })
      .catch(err => {
        return err
        
      });
  }
  patchRequest(url: string, body: any, headers?: HttpHeaders) {
    let request;
    if (headers) {
      request = this._HttpClient.patch(this.apiRoot + url, body, { headers });
    } else {
      request = this._HttpClient.patch(this.apiRoot + url, body);
    }
    return request
      .toPromise()
      .then(res => {
        return res;
      })
      .catch(err => {
        return err;
       
      });
  }
  deleteRequest(url: string, headers?: HttpHeaders) {
    let request;
    if (headers) {
      request = this._HttpClient.delete(this.apiRoot + url, { headers });
    } else {
      request = this._HttpClient.delete(this.apiRoot + url);
    }
    return request
      .toPromise()
      .then(res => {
        return res;
      })
      .catch(err => {
        return err;
        
      });
  }


  getScroll(url: string) {
    let request;
    
      request = this._HttpClient.get(this.apiRoot + url);
    
    return request
      .toPromise()
      .then(res => {
        return res;
      })
      .catch(err => {
        return err;
        
      });
  }
  
}
