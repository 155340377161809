import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class ApicallService {
headers: any;
  apiKey: any;
constructor(
  private http: HttpClient,
  private _ApiService: ApiService
  ){}


getJsonData(urlid:any){
  return this._ApiService.getRequest(
    `${environment.devapis}/Naukri/Json/Profile/${urlid}` 
  );
}
getResumeData(urlid:any){
  return this._ApiService.getRequest(`${environment.devapis}/Naukri/Json/Resume/${urlid}`)
}
getCommonApiData(urlid:any, federated:any){
    this.apiKey=environment.apiKey;
    return this._ApiService.getRequest(`${environment.deviosapi}/cps/candidate/${urlid}?federated=${federated}&source=test`,this.apiKey) 
  }
getCommonApiDataWithPortal(urlid:any){
  this.apiKey=environment.apiKey;
  return this._ApiService.getRequest(`${environment.deviosapi}/cps/candidate/${urlid}?source=test`,this.apiKey) 
}
// getApiData(urlid:any){
//   this.apiKey=environment.apiKey; https://devapis.globalhuntindia.com/cps/candidate/4b3159be-8e52-4dbd-a567-f2b207e46348?federated=dice&source=test
//   return this._ApiService.getRequest(`${environment.deviosapi}/cps/naukri/candidate/${urlid}?source=internalSearch`,this.apiKey)
// }
getResumeDowload(urlid:any){
  this.apiKey=environment.apiKey;
  return this._ApiService.getRequest(`${environment.deviosapi}/cps/candidateresumedata?id=${urlid}&source=test`,this.apiKey)
}
getDiceJson(urlid:any){
  return this._ApiService.getRequest(
    `${environment.devapis}/Dice/Json/Profile/${urlid}` 
  );
}
getDiceResume(urlid:any){
  return this._ApiService.getRequest(
    `${environment.devapis}/Dice/Json/Resume/${urlid}` 
  );
}
// getDiceApi(urlid:any){
//   this.apiKey=environment.apiKey;  
//   return this._ApiService.getRequest(`${environment.deviosapi}/cps/dice/candidate/${urlid}?source=test`,this.apiKey)
// }
getmonsterUsJson(urlid:any){
  return this._ApiService.getRequest(
    `${environment.devapis}/MonsterUS/Json/Profile/${urlid}`
  );
}
getMonsterResume(urlid:any){
  return this._ApiService.getRequest(
    `${environment.devapis}/MonsterUS/Json/Resume/${urlid}` 
  );
}
// getMonsterUsApi(urlid:any){
//   this.apiKey=environment.apiKey;
//   return this._ApiService.getRequest(`${environment.deviosapi}/cps/monster/candidate/${urlid}?source=test`,this.apiKey)
// }
indeedJson(urlid:any){
  return this._ApiService.getRequest(
    `${environment.devapis}/Indeed/Json/Profile/${urlid}`
  );
}

getLinkedinUsJson(urlid:any){
  // "https://in.linkedin.com/in/shashank-sharma-a1a59713b"
  return this._ApiService.getRequest(
    `${environment.devapis}/LinkedIn/Json/Profile/${urlid}`
  );
}
getLinkedinResume(urlid:any){
  return this._ApiService.getRequest(
    `${environment.devapis}/LinkedIn/Json/Resume/${urlid}` 
  );
}


}